

import axios from "axios";

import { userapiContext } from "../SecurityContext";



const loginUrl=`${userapiContext}/authenticate`

const registrationUrl=`${userapiContext}/user/register`

const changePasswordUrl=`${userapiContext}/user/securePasswordChange`

export const registerAPICall = (registerObj) => axios.post(registrationUrl, registerObj);

export const setNewPasswordCall=(changePasswordObject)=>{
  console.log(changePasswordObject);
  return axios.post(changePasswordUrl,changePasswordObject);
}


export const activataAPICall=(username,activationCode)=>{
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/activate/${username}/${activationCode}`,
    
  };
  
  return axios.request(config)

}


export const requestNewPassword=(username)=>{
  const passwordUrl=`${userapiContext}/user/resetPassword/${username}`;
  return axios.get(passwordUrl);
}

console.log()

export function testCors(){
    let data = JSON.stringify({
        "username": "testuser1",
        "password": "testuser1"
      });
      
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${userapiContext}/authenticate`,
        headers: { 
          'Content-Type': 'application/json', 
          'Cookie': 'JSESSIONID=66C4934177E9DD719E06BC09D2D8DFEA'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
}



export const loginAPICall = (username, password) => {
    console.log(loginUrl)
    return axios.post(loginUrl, {username, password},{ withCredentials: true })
};


export const storeRole=(role)=>localStorage.setItem("role",role);

export const storeToken = (token) => localStorage.setItem("token", token);

export const getToken = () => localStorage.getItem("token");

export const saveLoggedInUser = (username) => sessionStorage.setItem("authenticatedUser", username);

export const saveUserRole=(role)=>sessionStorage.setItem("role",role);

export const isAdmin=()=>{
  const currentRole=sessionStorage.getItem("role");

  if(currentRole==="admin"){
    return true
  }else{
    return false
  }
}

export const isUserLoggedIn = () => {

    const username = sessionStorage.getItem("authenticatedUser");

    if(username == null) {
        return false;
    }    
    else {
        return true;
    }   
}

export const getLoggedInUser = () => {
    const username = sessionStorage.getItem("authenticatedUser");
    return username;
}

export const getLogginUserRole=()=>{
  const role=sessionStorage.getItem("role");
  return role
}

export const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
}

export const sortOsmObjects=(allObjects)=>{
  let completeObjects=[]
  let incompleteObjects=[]
  allObjects.map((osm)=>{
    if(osm.status==="complete"){
      completeObjects.push(osm)
    }else{
      incompleteObjects.push(osm)
    }
  })

  console.log(completeObjects)
  //console.log(incompleteObjects)

  return {"complete":completeObjects,"incomplete":incompleteObjects}
}