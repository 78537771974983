import axios from "axios";
import { getToken } from "./AuthService";

import { userapiContext } from "../SecurityContext";

const templeteString = `${userapiContext}/osmObjects/update/1/complete`

/*  axios.interceptors.request.use(function (config) {

  config.headers['Authorization'] = getToken();

  console.log(config)
  console.log(getToken())

  return config;
}, function (error) {/
  // Do something with request error
  return Promise.reject(error);
});  */


export const markasComplete = (id) => {
   
  const authHeaderstr={"Authorization":getToken()}
  
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/osmObjects/update/${id}/complete`,
    headers: authHeaderstr
   

  };

  console.log(config)

  

 

  

  return axios.request(config)


}

export const checkAdminRight=(username)=> {

  const authHeaderstr={"Authorization":getToken()}

  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/getRoles/${username}`,
    headers: authHeaderstr

  };

  console.log(config)

  return axios.request(config);

}


export const getInactiveUsers=()=>{
  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/getInactiveUsers`,
    headers: authHeaderstr
  }

  console.log(config)
  return axios.request(config)
}

export const activateUser=(username)=>{

  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/simpleActivate/${username}`,
    headers: authHeaderstr
  }

  console.log(config)
  return axios.request(config)

}

export const getMessages=(username)=>{

  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/getMessages`,
    headers: authHeaderstr
  }

  console.log(config)
  return axios.request(config)

}


export const replyTo=(messageObject)=>{

  console.log(messageObject)

  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'post',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/sendMessage`,
    headers: authHeaderstr,
    data:messageObject
  }

  console.log(config)
  return axios.request(config)

}

export const getAllAdmins=()=>{

  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/allAdmins`,
    headers: authHeaderstr,
    
  }

  console.log(config)
  return axios.request(config)

}

export const getAllUsers=()=>{

  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/getAllUsers`,
    headers: authHeaderstr,
    
  }

  console.log(config)
  return axios.request(config)

}

export const getAllCities=()=>{
  const authHeaderstr={"Authorization":getToken()}

  const config={
    method: 'put',
    maxBodyLength: Infinity,
    url: `${userapiContext}/user/allCities`,
    headers: authHeaderstr,
    
  }

  console.log(config)
  return axios.request(config)
  
}

