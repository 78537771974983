import React from 'react'

import { ShowOSMObject } from './ShowOSMObject';

import { sortOsmObjects } from '../../services/AuthService';

import { useState, useEffect } from 'react'

import { userapiContext } from '../../SecurityContext';
import axios from 'axios';

import { getInactiveUsers } from '../../services/ResistrictedCalls';

import { DisplayInactiveUser } from './DisplayInactiveUser';

export const ShowInactiVeUsers = () => {

    const [inactiveUsersData, setInactiveUsersData] = useState([]);




    useEffect(() => {

        const fetchInactiveUsers = async () => {

            const res = await getInactiveUsers();
            const allInactiveUsers = res.data;
            /* const allKreisLabels=[]
            allKreise.map((m)=>{
             allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
            }); */








            console.log(allInactiveUsers)




            setInactiveUsersData(allInactiveUsers)



        };

        fetchInactiveUsers();
        console.log(inactiveUsersData.length)
    }, [])



    return (


        <div>

            {(inactiveUsersData.length === 0) &&


                <div className='row'>
                    <div className='col-md-6 offset-md-3'>
                        <div className='card mt-5'>
                            <img class="card-img-top" src={`${process.env.PUBLIC_URL}/welldone.svg`}
                                height="100"
                                width="100"
                                alt='Missing image' />
                            <div className='card-header'>
                                <h3 className='text-center'>  </h3>
                            </div>



                            <div className='card-body'>
                                <h2>Alle verifizierten Benutzer wurden aktiviert</h2>



                            </div>

                        </div>
                    </div>
                </div>

            }

            {(inactiveUsersData.length > 0) &&

                <div className='container'>
                    <div className='mt-3'>



                        {inactiveUsersData.map(o => (
                            // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
                            <DisplayInactiveUser key={o.id} status={o.isEnabled} username={o.username} message={o.message} id={o.id}></DisplayInactiveUser>
                        ))}

                        <br></br>



                    </div>

                </div>


            }




        </div>

    )
}
