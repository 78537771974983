

import { formatHalstelle } from "./utilityfunctions";

import { makeGeoJson } from "./utilityfunctions";
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, Popup, TileLayer,Tooltip } from 'react-leaflet'
import L from 'leaflet'
import MarkerClusterGroup from "react-leaflet-cluster";

import { sliceByCategory } from "./utilityfunctions";
import { getAllKreisOPNV } from "./utilityfunctions";

import axios from "axios";

import { DisplayAufzug } from "./DisplayAufzug";

import example from './exampleHalestelle.json'

import examplespnv from './exampleSPNV.json'

import { useEffect, useState } from "react";

import { currentContext } from "../../SecurityContext";

import { urlStem } from "../../services/UrlService";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export const ShowAufzugen = () => {

    const mystem=urlStem(currentContext)

    const [opnvData, setOpnvData] = useState([])
    const [spnvata, setSpnvData] = useState([])

    const [currentId, setCurrentId] = useState('')
    const [currentHaltestelle, setCurrentHaltestelle] = useState({})

    const [currentHoverStop,setCurrentHoverStop]=useState('')

    const [currentAufzuge, setCurrentAufzuge] = useState([])



    const [haltestelleData, setHaltestelleData] = useState([])



    useEffect(() => {

        const fetchOPNVKreise = async () => {

            const data = await getAllKreisOPNV()
            setOpnvData(data)
            //console.log(data)
            console.log("get some data")


        }

        fetchOPNVKreise();



    }, [])




    useEffect(() => {

        const fetchHaltestelleData = async () => {

            if ((Object.keys(currentHaltestelle).length === 0)) {

                console.log("first render")

            } else {


                const res = await axios.get(`${mystem}/bfrk_api/haltestellenobjektart/${currentHaltestelle.id}?oevart=${currentHaltestelle.oevart}`)
                const allhaltestelleObjects = res.data;
                const allAufuzge = sliceByCategory('Aufzug', allhaltestelleObjects)

                /* const allKreisLabels=[]
                allKreise.map((m)=>{
                 allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
                }); */


                console.log(allAufuzge)








                console.log(allhaltestelleObjects)


                setHaltestelleData(allhaltestelleObjects)
                setCurrentAufzuge(allAufuzge);


            }





        };

        fetchHaltestelleData();
        console.log(haltestelleData.length)
    }, [currentHaltestelle])


    const formattedHaltestelle1 = formatHalstelle(example[0])

    const trains = examplespnv

    const bus = example

    const formattedTrains = []

    trains.map((h) => {
        formattedTrains.push(formatHalstelle(h))
    })

    const formattedBus = []

    bus.map((h) => {
        formattedBus.push(formatHalstelle(h))
    })

    const trainGeojson = makeGeoJson(formattedTrains)

    const busGeojson = makeGeoJson(formattedBus)


    const bushaltestelle = formatHalstelle(example[1])



    const customIconBus = L.icon({
        iconUrl: process.env.PUBLIC_URL + '/bus.svg',
        iconSize: [30, 30], // specify the size of your SVG image
        // You can also specify other properties like iconAnchor, popupAnchor, etc. if needed
    });


    const customIconTrain = L.icon({
        iconUrl: process.env.PUBLIC_URL + '/train-6.svg',
        iconSize: [30, 30], // specify the size of your SVG image
        // You can also specify other properties like iconAnchor, popupAnchor, etc. if needed
      });



    const customIcon = L.icon({
        iconUrl: process.env.PUBLIC_URL + '/train-6.svg',
        iconSize: [30, 30], // specify the size of your SVG image
        // You can also specify other properties like iconAnchor, popupAnchor, etc. if needed
    });


    const getIcon = (objektart) => {
        if (objektart === "O") {
            return customIconBus
        } else {
            return customIconTrain
        }
    }

    const handleClick = (event) => {
        const { lat, lng } = event.latlng
        console.log(`Clicked at ${lat}, ${lng}`)


    }

    const handleMouseover=(name)=>{

      setCurrentHoverStop(name);

    }

    const handlePopupClick = (id, oevart, name) => {
        console.log("current id from handlePopupClick")
        console.log(id);
        console.log(oevart);



        setCurrentId(id);

        setCurrentHaltestelle({ "id": id, "oevart": oevart, "name": name })

    }



    return (

        <div className='containter'>
          <div class="row equal-height-row 
                        d-flex flex-wrap">
            <div class="col-md-6 bg-dark 
                            rounded p-4 d-flex 
                            flex-column 
                            justify-content-between">
              <div>
                <MapContainer
                  className="full-screen-map"
                  style={{ borderRadius: '20px' }}
    
                  center={[48.785575, 9.162710]}
                  zoom={10}
                  minZoom={3}
                  maxZoom={19}
                  maxBounds={[[-85.06, -180], [85.06, 180]]}
                  scrollWheelZoom={true}>
                  <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                  />
                  
    
    
                  <MarkerClusterGroup>
                    {trainGeojson.features.map((haltestelle, index) => (
                      <Marker
                        key={haltestelle.properties['@id']}
                        eventHandlers={{
                          click: () => {
                            handlePopupClick(haltestelle.properties['@id'], haltestelle.properties['oevart'], haltestelle.properties["name:en"])
                          },
                        }}
                        position={[haltestelle.geometry.coordinates[1], haltestelle.geometry.coordinates[0]]}
                        icon={customIconTrain}
                      >

                        <Tooltip>{haltestelle.properties["name:en"]}</Tooltip>
                        
                        <Popup>
                          {haltestelle.properties.name}
                          <br />
                          {haltestelle.properties['name:en']}
                        </Popup>
                      </Marker>
                    ))}
                  </MarkerClusterGroup>
    
                  <MarkerClusterGroup>
                    {busGeojson.features.map((haltestelle, index) => (
                      <Marker
                        key={haltestelle.properties['@id']}
                        eventHandlers={{
                          click: () => {
                            handlePopupClick(haltestelle.properties['@id'], haltestelle.properties['oevart'], haltestelle.properties["name:en"])
                            //console.log(haltestelle.properties['@id'])
                          },
                        }}
    
                        position={[haltestelle.geometry.coordinates[1], haltestelle.geometry.coordinates[0]]}
                        icon={customIconBus}
                      >
                        <Popup>
                          {haltestelle.properties.name}
                          <br />
                          {haltestelle.properties['name:en']}
                        </Popup>
                      </Marker>
                    ))}
                  </MarkerClusterGroup>
                </MapContainer>
    
    
    
              </div>
            </div>
            <div class="col-md-6 bg-light rounded 
                            p-4 d-flex flex-column 
                            justify-content-between">
              <div>
                <h2 class="column-title">
                  {currentHaltestelle.name}
                </h2>
    
                {(Object.keys(currentHaltestelle).length === 0) &&

                <div className="row">
                    <div className="row col-6">
                    <p> Bitte Wahlen eine Haltestelle aus (zoomen und drauf clicken)</p>
                    </div>
                </div>
    
                
                
                
                }
    
                {!(Object.keys(currentHaltestelle).length === 0)&&(currentAufzuge.length===0) &&
                <p>Es gibt keine Aufzüge in dieser Haltestelle</p>
                
                }
                
    
                {(currentAufzuge.length > 0) &&
    
                
                  currentAufzuge.map(utility => (
                    // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
                    <DisplayAufzug key={utility.id} id={utility.id}></DisplayAufzug>
                  ))
                
    
    
    
    
                }
    
    
    
    
              </div>
            </div>
    
          </div>
    
    
    
        </div>
    
    
    
    
    
    
      );




}

