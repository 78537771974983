import axios from 'axios';
import { useEffect, useState } from 'react';

import { markasComplete } from '../../services/ResistrictedCalls';
import { activateUser } from '../../services/ResistrictedCalls';

import { SpinnerLoading } from './SpinnerLoading';
export const DisplayInactiveUser = (props) => {

    const [currentStatus, setCurrentStatus] = useState('incomplete');

    console.log(props.status)

    const [updateFailure, setUpdateFailure] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const id = props.id;
    const username=props.username;

    const [isUpdating,setIsUpdating]=useState(false);

    useEffect(() => {
        console.log("current status changed")


    }, [currentStatus])





    async function handleChange() {

        setIsUpdating(true)

        console.log(id);

        await activateUser(username).then((response) => {
            console.log("marked as complete function success")
            setUpdateSuccess(true)
            setCurrentStatus("complete")
            setIsUpdating(false)
        }).catch(error => {
            console.error("error");
            setUpdateFailure(true);
            setIsUpdating(false)

        })



        console.log("Marked as complete");
    }


    if(isUpdating) return <SpinnerLoading></SpinnerLoading>
    return (

        <div className='row'>
            <div className='col-sm-8'>

                <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
                    <div className='row g-0'>
                        <div className='col-md-2 '>

                            <div className='d-none d-lg-block'>

                                {(currentStatus === "complete") &&
                                    <img src={`${process.env.PUBLIC_URL}/completed.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }

                                {(currentStatus === "incomplete") &&
                                    <img src={`${process.env.PUBLIC_URL}/newuser.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }




                            </div>


                            <div className='d-lg-none d-flex justify-content-center 
                                                   align-items-center ml-3 mr-3'>

                                {(currentStatus === "complete") &&
                                    <img src={`${process.env.PUBLIC_URL}/completed.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }

                                {(currentStatus === "incomplete") &&
                                    <img src={`${process.env.PUBLIC_URL}/newuser.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }




                            </div>
                        </div>
                        <div className='col-md-6 '>
                            <div className='card-body'>
                                <h5 className='card-title'>
                                    User Id: {props.id}

                                </h5>
                                <h6>


                                </h6>
                                <p className='card-text'>

                                    <p>Message : {props.message}</p>
                                    <p> Is Enabled : {currentStatus}</p>
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-center align-items-center'>

                            {currentStatus === "incomplete" &&
                                <button className="btn btn-primary" onClick={handleChange}>
                                    Konto Aktivieren
                                </button>
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}