import { NavLink } from "react-router-dom/cjs/react-router-dom";

import { isUserLoggedIn } from "../../services/AuthService";

import { logout } from "../../services/AuthService";
import { useEffect } from "react";

import { useState } from "react";

import { isAdmin } from "../../services/AuthService";

import { Icon } from 'react-icons-kit'
import { ic_connect_without_contact } from 'react-icons-kit/md/ic_connect_without_contact'
import { ic_forward_to_inbox_twotone } from 'react-icons-kit/md/ic_forward_to_inbox_twotone'



export const Navbar = () => {

  const [toggleLogin, setToggleLogin] = useState(0);

  const isAuth = isUserLoggedIn();

  const userIsAdmin = isAdmin();

  useEffect(() => {
    console.log("login state changed")


  }, [toggleLogin])


  const handleLogout = (toggleLogin) => {
    setToggleLogin(toggleLogin + 1);



    console.log("log me off")

    logout();

  }
  return (
    <nav className='navbar navbar-expand-lg navbar-dark main-color py-3'>
      <div className='container-fluid'>
        <a class="navbar-brand" href="#">
          <img src={`${process.env.PUBLIC_URL}/cross.svg`}
            width='40'
            height='40'
            alt='Missing image'
          />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNavDropdown'
          aria-controls='navbarNavDropdown'
          aria-expanded='false'
          aria-label='Toggle Navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavDropdown'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/home'>
                Home
              </NavLink>
            </li>

            {userIsAdmin &&

              <li className='nav-item'>
                <NavLink className='nav-link' to='/Admin'>
                  Admin Tasks
                </NavLink>
              </li>
            }
            {/*   <li className='nav-item'>
                <NavLink className='nav-link' to='/search'>
                  Suche 
                </NavLink>
              </li> */}
            {/* <li className='nav-item'>
              <NavLink className='nav-link' to='/OPNV'>
                ÖPNV
              </NavLink>
            </li>

            <li className='nav-item'>
              <NavLink className='nav-link' to='/SPNV'>
                SPNV
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/AufzugKarte">
                Aufzug Karte
              </NavLink>
            </li> */}

            {isAuth &&

              <li className='nav-item'>
                <NavLink className='nav-link' to='/Inbox'>
                  <p>
                    <Icon size={32} icon={ic_forward_to_inbox_twotone}></Icon>
                    &nbsp; Inbox
                  </p>
                </NavLink>
              </li>
            }

            {isAuth &&

              <li className='nav-item'>
                <NavLink className='nav-link' to='/Networking'>
                  <p>
                    <Icon size={32} icon={ic_connect_without_contact}></Icon>
                    &nbsp; Vernetzen
                  </p>
                </NavLink>
              </li>
            }


          </ul>
          <ul className='navbar-nav ms-auto'>
            {<li className='nav-item m-1'>

              {isAuth &&

                <NavLink className='nav-link' to='/User' onClick={handleLogout}>
                  <div class="container">
                    <div class="col">
                      <div class="row">
                        {/*  <svg width="40" height="40" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.6403809,6c-6.1400146,0-11.1400146,5-11.1400146,11.1400146c0,6.1399536,5,11.1399536,11.1400146,11.1399536  c6.1499634,0,11.1499634-5,11.1499634-11.1399536C37.7903442,11,32.7903442,6,26.6403809,6z" fill="#ffffff" />
        <path d="M6.3303833,47.7699585c-0.0100098,0.2700195,0.0899658,0.5300293,0.2799683,0.7200317  s0.4500122,0.2999878,0.7200317,0.2999878h7.1899414v-4.8099976c0-0.5499878,0.4500122-1,1-1c0.5500488,0,1,0.4500122,1,1v4.8099976  h14.2900391c-0.9100342-1.7799683-1.4199829-3.7999878-1.4199829-5.9299927c0-4.9799805,2.7799683-9.3300171,6.8799438-11.5499878  c-1.1099854-0.6699829-2.2799683-1.2200317-3.4899902-1.6500244c-0.4299927-0.1499634-0.9099731,0.0100098-1.1599731,0.3900146  l-5.4799805,8.1400146l-5.4700317-8.1600342c-0.2600098-0.3800049-0.7399902-0.539978-1.1699829-0.3800049  C11.8503418,32.3800049,6.5603638,39.6599731,6.3303833,47.7699585z" fill="#ffffff" />
        <path d="M53.6703491,42.8599854c0-6.1400146-4.9899902-11.1400146-11.1400146-11.1400146  c-6.1399536,0-11.1399536,5-11.1399536,11.1400146S36.3903809,54,42.5303345,54C48.6803589,54,53.6703491,49,53.6703491,42.8599854z   M40.6103516,47.5700073v-2.7900391h-2.789978c-0.3900146,0-0.7000122-0.3099976-0.7000122-0.7000122v-2.4399414  c0-0.3900146,0.3099976-0.7000122,0.7000122-0.7000122h2.789978v-2.7900391c0-0.3899536,0.3099976-0.6999512,0.7000122-0.6999512  h2.4400024c0.3999634,0,0.7099609,0.3099976,0.7099609,0.6999512v2.7900391h2.7900391  c0.3900146,0,0.7000122,0.3099976,0.7000122,0.7000122v2.4399414c0,0.3900146-0.3099976,0.7000122-0.7000122,0.7000122h-2.7900391  v2.7900391c0,0.3899536-0.3099976,0.6999512-0.7099609,0.6999512h-2.4400024  C40.9203491,48.2699585,40.6103516,47.9599609,40.6103516,47.5700073z" fill="#ffffff" />
      </svg> */}

                        {/*  <svg width="40" height="40" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                          <path d="M26.6403809,6c-6.1400146,0-11.1400146,5-11.1400146,11.1400146c0,6.1399536,5,11.1399536,11.1400146,11.1399536  c6.1499634,0,11.1499634-5,11.1499634-11.1399536C37.7903442,11,32.7903442,6,26.6403809,6z" fill="#ffffff" />
                          <path d="M6.3303833,47.7699585c-0.0100098,0.2700195,0.0899658,0.5300293,0.2799683,0.7200317  s0.4500122,0.2999878,0.7200317,0.2999878h7.1899414v-4.8099976c0-0.5499878,0.4500122-1,1-1c0.5500488,0,1,0.4500122,1,1v4.8099976  h14.2900391c-0.9100342-1.7799683-1.4199829-3.7999878-1.4199829-5.9299927c0-4.9799805,2.7799683-9.3300171,6.8799438-11.5499878  c-1.1099854-0.6699829-2.2799683-1.2200317-3.4899902-1.6500244c-0.4299927-0.1499634-0.9099731,0.0100098-1.1599731,0.3900146  l-5.4799805,8.1400146l-5.4700317-8.1600342c-0.2600098-0.3800049-0.7399902-0.539978-1.1699829-0.3800049  C11.8503418,32.3800049,6.5603638,39.6599731,6.3303833,47.7699585z" fill="#ffffff" />
                          <path d="M53.6703491,42.8599854c0-6.1400146-4.9899902-11.1400146-11.1400146-11.1400146  c-6.1399536,0-11.1399536,5-11.1399536,11.1400146S36.3903809,54,42.5303345,54C48.6803589,54,53.6703491,49,53.6703491,42.8599854z   M40.6103516,47.5700073v-2.7900391h-2.789978c-0.3900146,0-0.7000122-0.3099976-0.7000122-0.7000122v-2.4399414  c0-0.3900146,0.3099976-0.7000122,0.7000122-0.7000122h2.789978v-2.7900391c0-0.3899536,0.3099976-0.6999512,0.7000122-0.6999512  h2.4400024c0.3999634,0,0.7099609,0.3099976,0.7099609,0.6999512v2.7900391h2.7900391  c0.3900146,0,0.7000122,0.3099976,0.7000122,0.7000122v2.4399414c0,0.3900146-0.3099976,0.7000122-0.7000122,0.7000122h-2.7900391  v2.7900391c0,0.3899536-0.3099976,0.6999512-0.7099609,0.6999512h-2.4400024  C40.9203491,48.2699585,40.6103516,47.9599609,40.6103516,47.5700073z" fill="#ffffff" />
                        </svg> */}

                        <img src={`${process.env.PUBLIC_URL}/logoff.svg`}
                          width='15'
                          height='40'
                          alt='Missing image'
                        />










                        {/*  <img src={`${process.env.PUBLIC_URL}/user4.svg`}
                                           width='15'
                                           height='40'
                                           alt='Missing image'
                                       /> */}

                      </div>

                      <div class="row">


                        Abmelden


                      </div>


                    </div>
                  </div>



                </NavLink>

              }


              {!(isAuth) &&

                <NavLink className='nav-link' to='/User'>
                  <div class="container">
                    <div class="col">
                      <div class="row">
                        {/*  <svg width="40" height="40" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
<path d="M26.6403809,6c-6.1400146,0-11.1400146,5-11.1400146,11.1400146c0,6.1399536,5,11.1399536,11.1400146,11.1399536  c6.1499634,0,11.1499634-5,11.1499634-11.1399536C37.7903442,11,32.7903442,6,26.6403809,6z" fill="#ffffff" />
<path d="M6.3303833,47.7699585c-0.0100098,0.2700195,0.0899658,0.5300293,0.2799683,0.7200317  s0.4500122,0.2999878,0.7200317,0.2999878h7.1899414v-4.8099976c0-0.5499878,0.4500122-1,1-1c0.5500488,0,1,0.4500122,1,1v4.8099976  h14.2900391c-0.9100342-1.7799683-1.4199829-3.7999878-1.4199829-5.9299927c0-4.9799805,2.7799683-9.3300171,6.8799438-11.5499878  c-1.1099854-0.6699829-2.2799683-1.2200317-3.4899902-1.6500244c-0.4299927-0.1499634-0.9099731,0.0100098-1.1599731,0.3900146  l-5.4799805,8.1400146l-5.4700317-8.1600342c-0.2600098-0.3800049-0.7399902-0.539978-1.1699829-0.3800049  C11.8503418,32.3800049,6.5603638,39.6599731,6.3303833,47.7699585z" fill="#ffffff" />
<path d="M53.6703491,42.8599854c0-6.1400146-4.9899902-11.1400146-11.1400146-11.1400146  c-6.1399536,0-11.1399536,5-11.1399536,11.1400146S36.3903809,54,42.5303345,54C48.6803589,54,53.6703491,49,53.6703491,42.8599854z   M40.6103516,47.5700073v-2.7900391h-2.789978c-0.3900146,0-0.7000122-0.3099976-0.7000122-0.7000122v-2.4399414  c0-0.3900146,0.3099976-0.7000122,0.7000122-0.7000122h2.789978v-2.7900391c0-0.3899536,0.3099976-0.6999512,0.7000122-0.6999512  h2.4400024c0.3999634,0,0.7099609,0.3099976,0.7099609,0.6999512v2.7900391h2.7900391  c0.3900146,0,0.7000122,0.3099976,0.7000122,0.7000122v2.4399414c0,0.3900146-0.3099976,0.7000122-0.7000122,0.7000122h-2.7900391  v2.7900391c0,0.3899536-0.3099976,0.6999512-0.7099609,0.6999512h-2.4400024  C40.9203491,48.2699585,40.6103516,47.9599609,40.6103516,47.5700073z" fill="#ffffff" />
</svg> */}

                        <svg width="40" height="40" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                          <path d="M26.6403809,6c-6.1400146,0-11.1400146,5-11.1400146,11.1400146c0,6.1399536,5,11.1399536,11.1400146,11.1399536  c6.1499634,0,11.1499634-5,11.1499634-11.1399536C37.7903442,11,32.7903442,6,26.6403809,6z" fill="#ffffff" />
                          <path d="M6.3303833,47.7699585c-0.0100098,0.2700195,0.0899658,0.5300293,0.2799683,0.7200317  s0.4500122,0.2999878,0.7200317,0.2999878h7.1899414v-4.8099976c0-0.5499878,0.4500122-1,1-1c0.5500488,0,1,0.4500122,1,1v4.8099976  h14.2900391c-0.9100342-1.7799683-1.4199829-3.7999878-1.4199829-5.9299927c0-4.9799805,2.7799683-9.3300171,6.8799438-11.5499878  c-1.1099854-0.6699829-2.2799683-1.2200317-3.4899902-1.6500244c-0.4299927-0.1499634-0.9099731,0.0100098-1.1599731,0.3900146  l-5.4799805,8.1400146l-5.4700317-8.1600342c-0.2600098-0.3800049-0.7399902-0.539978-1.1699829-0.3800049  C11.8503418,32.3800049,6.5603638,39.6599731,6.3303833,47.7699585z" fill="#ffffff" />
                          <path d="M53.6703491,42.8599854c0-6.1400146-4.9899902-11.1400146-11.1400146-11.1400146  c-6.1399536,0-11.1399536,5-11.1399536,11.1400146S36.3903809,54,42.5303345,54C48.6803589,54,53.6703491,49,53.6703491,42.8599854z   M40.6103516,47.5700073v-2.7900391h-2.789978c-0.3900146,0-0.7000122-0.3099976-0.7000122-0.7000122v-2.4399414  c0-0.3900146,0.3099976-0.7000122,0.7000122-0.7000122h2.789978v-2.7900391c0-0.3899536,0.3099976-0.6999512,0.7000122-0.6999512  h2.4400024c0.3999634,0,0.7099609,0.3099976,0.7099609,0.6999512v2.7900391h2.7900391  c0.3900146,0,0.7000122,0.3099976,0.7000122,0.7000122v2.4399414c0,0.3900146-0.3099976,0.7000122-0.7000122,0.7000122h-2.7900391  v2.7900391c0,0.3899536-0.3099976,0.6999512-0.7099609,0.6999512h-2.4400024  C40.9203491,48.2699585,40.6103516,47.9599609,40.6103516,47.5700073z" fill="#ffffff" />
                        </svg>










                        {/*  <img src={`${process.env.PUBLIC_URL}/user4.svg`}
                           width='15'
                           height='40'
                           alt='Missing image'
                       /> */}

                      </div>

                      <div class="row">


                        Benutzerbereich


                      </div>


                    </div>
                  </div>



                </NavLink>

              }



            </li>}
          </ul>
        </div>
      </div>
    </nav>
  );
};
