import React, { useState, useEffect } from "react"
import { SearchBook } from "./SearchBook";

import { SpinnerLoading } from "./SpinnerLoading";
import axios from "axios"
import { Pagination } from "./Pagination";

export const SearchBooksPage = () => {

    const [books, setBooks] = useState([]);
    
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [booksPerPage] = useState(5);
    const [totalAmountOfBooks, setTotalAmountOfBooks] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const baseurl = "http://localhost:3500/books"

    useEffect(() => {
        const fetchBooks = async () => {
          const res = await axios.get(baseurl);
          const pagedRes=await axios.get(`${baseurl}?_page=${currentPage}&_limit=5`);
          setBooks(pagedRes.data);
          setTotalAmountOfBooks(res.data.length);
          setTotalPages(Math.ceil(res.data.length/5));
        };
    
        fetchBooks();
      }, [currentPage]);


      const indexOfLastBook = currentPage * booksPerPage;
      const indexOfFirstBook= indexOfLastBook - booksPerPage;
      let lastItem = booksPerPage * currentPage <= totalAmountOfBooks ?
         booksPerPage * currentPage : totalAmountOfBooks;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


   

	if (!books) return <SpinnerLoading/>

  

  
    return (
        <div>
            <div className='container'>
                <div>
                    <div className='row mt-5'>
                        <div className='col-6'>
                            <div className='d-flex'>
                                <input className='form-control me-2' type='search'
                                    placeholder='Search' aria-labelledby='Search'/>
                                <button className='btn btn-outline-success'>
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='dropdown'>
                                <button className='btn btn-secondary dropdown-toggle' type='button'
                                    id='dropdownMenuButton1' data-bs-toggle='dropdown'
                                    aria-expanded='false'>
                                    Category
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                    <li>
                                        <a className='dropdown-item' href='#'>
                                            All
                                        </a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item' href='#'>
                                            Front End
                                        </a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item' href='#'>
                                            Back End
                                        </a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item' href='#'>
                                            Data
                                        </a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item' href='#'>
                                            DevOps
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                            <div className='mt-3'>
                                <h5>Number of results: (22)</h5>
                            </div>
                            <p>
                            {indexOfFirstBook + 1} to {lastItem} of {totalAmountOfBooks} items:
                            </p>
                            {books.map(book => (
                                <SearchBook book={book} key={book.id} />
                            ))}
                            {totalPages>1 &&
                            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}></Pagination>}
                </div>
            </div>
        </div>
    );
}