import { useState,useEffect } from "react"
import axios from 'axios';

import Select from 'react-select'

import { SearchBook } from "./SearchBook";
import { ShowUtility } from "./ShowUtility";
import { Pagination } from "./Pagination";
import { ShowMoreInfo } from "./ShowMoreInfo";

import { currentContext } from "../../SecurityContext";

import { urlStem } from "../../services/UrlService";
export const SearchOPNVPage=()=>{

    const mystem=urlStem(currentContext)


    const baseurlHaltestelle=`${mystem}/bfrk_api/haltestelle?oevart=O&kreisid=`

    const [kreisData,setKreisData]=useState([])

    
    
    const [kreisSelected,setKreisSelected]=useState('')

    const [idKreisSelected,setIdKreisSelected]=useState('')

    const [idHaltestelleSelected,setidHaltestelleSelected]=useState('')

    const [haltestelleSelected,setHaltestelleSelected]=useState('')

    const[haltestelleData,setHaltestelleData]=useState([])

    const [utilitiesData,setUtilitiesData]=useState([])

    const [haltestelleName,setHaltestelleName]=useState('')

    //pagination related variables

    const [currentUtilities,setCurrentUtilities]=useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [utilitiesPerPage] = useState(5);
    const [totalAmountOfUtilities, setTotalAmountOfUtilities] = useState(0);
    const [totalPages, setTotalPages] = useState(0);


    


    useEffect(()=>{

        const fetchKries=async()=>{
 
         const res=await axios.get(`${mystem}/bfrk_api/kreise?oevart=O`)
         const allKreise=res.data;
         /* const allKreisLabels=[]
         allKreise.map((m)=>{
          allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
         }); */

      

         
 
        
         
         
 
         const allKreiseCategories=[]
         allKreise.map((m)=>{
           allKreiseCategories.push({"label":m.name,"value":m.kreisschluessel})
         })

         

         
            setKreisData(allKreiseCategories)
        };  
 
           fetchKries();
           console.log(utilitiesData.length)
     },[])

     useEffect(() => {
      console.log("updated kreise")
    
     
    }, [kreisData])


     useEffect(()=>{

        const fetchHaltestelles = async () => {
            
            console.log(kreisSelected)
            const hatestelleurl=`${baseurlHaltestelle}${idKreisSelected}`;
            console.log(hatestelleurl)
            const res = await axios.get(hatestelleurl);
            const allHaltestelleData=res.data
            const haltestelleOptions=[]
            allHaltestelleData.map((h)=>{
              h.dhid.length>0 &&
                haltestelleOptions.push({"label":`${h.name}, ${h.ortsteil}, Gemeinde: ${h.gemeinde}`,"value":h.dhid})
            })
            setHaltestelleData(haltestelleOptions)
            



         
            
            
          };

         if(idKreisSelected){
            console.log("active state")
            fetchHaltestelles()
         }

       
    },
    
    [idKreisSelected]);

    useEffect(()=>{
      console.log("re-render after kreis selected name change")
    },[kreisSelected])




    useEffect(()=>{
      const fetchUtilities=async ()=>{

            const utilityurl=`${mystem}/bfrk_api/haltestellenobjektart/${idHaltestelleSelected}?oevart=O`;
            console.log(utilityurl)
            const res = await axios.get(utilityurl);
            const allHaltestelleData=res.data
            const haltestelleUtilities=[]
            allHaltestelleData.map((u)=>{
              
                haltestelleUtilities.push({"id":u.id,"objektart":u.objektart,"img":`/${u.objektart.toLowerCase()}.svg`})
            })
           setUtilitiesData(haltestelleUtilities)



      };

      if(idHaltestelleSelected){
        fetchUtilities();

      }

      
    }
      ,[idHaltestelleSelected])


      useEffect(() => {
        const fetchUtilities =  () => {

          var startIndex
          var totalUtilities=utilitiesData.length
          if (currentPage===1){
             startIndex=0
          } else{
            startIndex=(currentPage-1)*utilitiesPerPage+1
          }
          setTotalAmountOfUtilities(utilitiesData.length)  
          setCurrentUtilities(utilitiesData.slice(startIndex,startIndex+utilitiesPerPage))
          setTotalPages(Math.ceil(totalUtilities/5))
          /* const res = await axios.get(baseurl);
          const pagedRes=await axios.get(`${baseurl}?_page=${currentPage}&_limit=5`);
          setBooks(pagedRes.data);
          setTotalAmountOfBooks(res.data.length);
          setTotalPages(Math.ceil(res.data.length/5)); */
        };
    
        fetchUtilities();
      }, [utilitiesData,currentPage]);


      const indexOfLastUtility = currentPage * utilitiesPerPage;
      const indexOfFirstUtility= indexOfLastUtility - utilitiesPerPage;
      let lastItem = utilitiesPerPage * currentPage <= totalAmountOfUtilities ?
         utilitiesPerPage * currentPage : totalAmountOfUtilities;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


     const handleKreisChange=(e)=>{

        /* const currentSelectedKreis = e.target.value;
        const currentSelectedName=e.target.options[e.target.selectedIndex].text */
        setHaltestelleData([])
        setKreisSelected(e.value);
        setIdKreisSelected(e.value);


        /* setIdKreisSelected(currentSelectedKreis)
        setKreisSelected(currentSelectedName); */

        setidHaltestelleSelected('')
        setHaltestelleSelected('')
        setHaltestelleName('')
        setUtilitiesData([])
        /* console.log(currentSelectedKreis);
        console.log(currentSelectedName); */
        
      
        

     }

     const handleHaltestelleChange=(e)=>{
        /* const currentSelectedHaltestelle=e.value;
        const currentSelectedName=e.options[e.target.selectedIndex].text;

        console.log(currentSelectedHaltestelle)
        console.log(currentSelectedName) */

        setidHaltestelleSelected(e.value)
        setHaltestelleSelected(e.value)
        setHaltestelleName(e.label)
    
     }

     return (
        <div>
        <div className='container'>
            <div>
                <div className='row mt-5'>
                    
                    <div className='col-6'>
                                <Select 
                        placeholder='Select a Kreis'
                        isSearchable
                        // value={kreisSelected}
                        options={kreisData}
                        onChange={handleKreisChange}
                                />
                    </div>
                    </div>

                    <br></br>
                <div className='row mt-5'>    

                    <div className="col-6">

                    <Select 
                                    placeholder='Select a Haltestelle'
                                    isSearchable
                                    
                                    value={idHaltestelleSelected ? { "label": haltestelleName, "value": idHaltestelleSelected } : null}
                                    options={haltestelleData}
                                    onChange={handleHaltestelleChange}
                                    />

                    </div>



                </div>
                        <div className='mt-3'>
                    <br></br>
                    <br></br>

                                {utilitiesData.length>0 && 
                                <h2>
                                There are {utilitiesData.length} utilities at this stop
                                </h2>
                                }
                        </div>
                        
                        {currentUtilities.map(utility => (
                            // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
                            <ShowMoreInfo key={utility.id} utility={utility} haltestelle={idHaltestelleSelected}></ShowMoreInfo>
                        ))}

                        {totalPages>1 &&
                                                    <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}></Pagination>}
                       
            </div>
        </div>
    </div>
     );
}