

import "./App.css"

import { Footer } from "./components/homepage/Footer"

import { ExploreTopBooks } from "./components/homepage/ExploreTopBooks"

import { ExperimentalCarousel } from "./components/homepage/ExperimentalCarousel"

import { Navbar } from "./components/homepage/Navbar"



import { SearchBooksPage } from "./components/utils/SearchBooksPage"
import { Redirect, Route, Switch } from "react-router-dom/cjs/react-router-dom"


import { Carousel } from "./components/homepage/Carousel"

import { SearchOPNVPage } from "./components/utils/SearchOPNVPage"

import { SearchSPNVPage } from "./components/utils/SearchSPNVPage"
import { LoginComponent } from "./components/homepage/LoginComponent"
import { RegiserComponent } from "./components/homepage/RegisterComponent"
import { ActivateAccount } from "./components/homepage/ActivateAccount"
/* import RequestPasswordChange from "./components/homepage/RequestPasswordChange"
import ResetPassword from "./components/homepage/ResetPassword" */
import { UserServices } from "./components/homepage/UserServices"

import { ShowEdits } from "./components/utils/ShowEdits"

import { ShowAufzugen } from "./components/maps/ShowAufzugen"

import { Heros } from "./components/homepage/Heros"

import{Inbox} from "./components/homepage/Messaging/Inbox"
import { AllUsers } from "./components/homepage/Networking/AllUsers"
import { SchwerpunktDisplay } from "./components/homepage/GridDisplay/SchwerpunktDisplay"
import { ShowInactiVeUsers } from "./components/utils/ShowInactiveUsers"

import { AllUserstest } from "./components/homepage/Networking/AllUserstest"
import { AllVeranstaltungen } from "./components/homepage/Veanstaltungen/AllVeranstaltugen"



function App() {



	return (
		<>
			<div className='d-flex flex-column min-vh-100'>
				<Navbar></Navbar>

				<div>





					<Switch>

						<Route path='/' exact>
							<Redirect to='/home'></Redirect>

						</Route>

						<Route path='/home'>
							<Heros></Heros>
							{/* <ExploreTopBooks></ExploreTopBooks> */}
							{/* <Carousel></Carousel> */}
							
							<SchwerpunktDisplay></SchwerpunktDisplay>



						</Route>

						<Route path='/search'>
							<SearchBooksPage></SearchBooksPage>

						</Route>

						<Route path='/OPNV'>
							<SearchOPNVPage></SearchOPNVPage>

						</Route>

						<Route path='/SPNV'>
							<SearchSPNVPage></SearchSPNVPage>

						</Route>

						<Route path="/Edits">
							<ShowEdits></ShowEdits>

						</Route>

						<Route path="/Inbox">
							<Inbox></Inbox>
						</Route>

						<Route path='/Networking'>
							<AllUsers></AllUsers>
						</Route>

						<Route path="/AufzugKarte">
							<ShowAufzugen></ShowAufzugen>


						</Route>

						<Route path="/Admin">
							<ShowInactiVeUsers></ShowInactiVeUsers>
						</Route>

						<Route path="/Events">
							<AllVeranstaltungen></AllVeranstaltungen>

						</Route>

						<Route path='/User'>
							{/* <LoginComponent></LoginComponent>
				<RegiserComponent></RegiserComponent> */}

							<UserServices></UserServices>

						</Route>

					</Switch>
				</div>




			</div>

			<Footer />

		</>
	)
}

export default App
