import { useState, useEffect } from "react"

import { Slider } from "../../utils/Slider"

import { getUsersSpeakingLang } from "./UserUtilityFunctions"

import { getAllCities } from "../../../services/ResistrictedCalls"
import Select from 'react-select'

export const UserFilter = ({ Users, setUsers, allUsers, setCurrentUsername }) => {

    const [cities, setCities] = useState([])

    const [idCitySelected,setIdCitySelected]=useState('')

    const [allCities,setAllCities]=useState([])

    const [cityOptions,setCityOptions]=useState([])

    const [citySelected,setCitySelected]=useState('')

    const [toggleDeutsch, setToggleDeutsch] = useState(false)

    const [togglePolski, setTogglePolski] = useState(false)

    const [toggleBulgarisch, setToggleBulgarisch] = useState(false)

    const [filterOnCity,setFilterOnCity]=useState(false)

    useEffect(() => {

        const fetchCities = async () => {
            const res = await getAllCities();
            setAllCities(res.data)
            var options=[]
            res.data.map((c)=>{
                options.push({"label":c,"value":c})
            })

            setCityOptions(options)

        }

        fetchCities();

    }, [])

    useEffect(() => {
        console.log(cityOptions)
      
    }, [cityOptions])
    

    function getUsersInCity(cityName, UserList) {
        var usersInCity = []
        UserList.map((u) => {
            if (u.city === cityName) {
                usersInCity.push(u)
            }
        })

        return usersInCity

    }




    function handleDeutschToggleChange(toggleDeutsch) {
        setToggleDeutsch(!toggleDeutsch)
    }

    function handlePolskiToggle(togglePolski) {
        setTogglePolski(!togglePolski)
    }

    function handleToggleBulgarisc(toggleBulgarisch) {
        setToggleBulgarisch(!toggleBulgarisch)
    }


    function getUserByUsername(userCollection, username) {
        return userCollection.find(x => x.username === username)
    }

    const handleCityChange=(e)=>{
        console.log(e.value)
        setIdCitySelected(e.value)
        setCitySelected(e.value)
        if(e.value.length>0){
            console.log('filter on city')
            setFilterOnCity(true)
        }
    }

    const userInSet = (username, A) => {
        if (getUserByUsername(A, username)) {
            return true
        } else {
            return false
        }
    }

    function UserSetUnion(A, B) {
        const uSet1 = A;

        B.map((u) => {
            if (!userInSet(u.username, A)) {
                uSet1.push(u)

            }
        })
        return uSet1
    }

    function applyFilter(allUsersSet) {

        // allUsersSet is the largest collection of user. Its coming in as prop allUsers. it should not be modified

        var filteredUsers = []

        console.log(toggleDeutsch)

        console.log(allUsersSet)

        const usersWithGerman = getUsersSpeakingLang(allUsersSet, 'Deutsch')
        console.log(usersWithGerman)
        const usersWithPolski = getUsersSpeakingLang(allUsersSet, 'Polnisch')
        const usersWitBulgarian = getUsersSpeakingLang(allUsersSet, 'Bulgarisch')

        if (toggleDeutsch) {
            console.log("adding german speakers")

            if (usersWithGerman.length > 0) {
                usersWithGerman.map((u) => {
                    filteredUsers.push(u)
                })
                console.log(filteredUsers)
            }

        }

        if (toggleBulgarisch) {
            if (usersWitBulgarian.length > 0) {
                filteredUsers = UserSetUnion(filteredUsers, usersWitBulgarian)
                console.log(filteredUsers)
            }

        }
        if (togglePolski) {
            if (usersWithPolski.length > 0) {
                filteredUsers = UserSetUnion(filteredUsers, usersWithPolski)
                console.log(filteredUsers)
            }
        }

        if((filterOnCity) &&(filteredUsers.length>0)){
            const inTheCity=getUsersInCity(idCitySelected,filteredUsers)
            filteredUsers=inTheCity

        }

        if (!(toggleDeutsch) && !(togglePolski) && !(toggleBulgarisch) && !(filterOnCity)) {
            console.log(citySelected)
            return allUsersSet

        }else if(filterOnCity  &&!(toggleDeutsch) &&!(togglePolski) &&!(toggleBulgarisch)){
            return getUsersInCity(idCitySelected,allUsersSet)

        } else if(filterOnCity &&!(filterOnCity  &&!(toggleDeutsch) &&!(togglePolski) &&!(toggleBulgarisch))) {
            return getUsersInCity(idCitySelected,filteredUsers)
            
        }  else {
            console.log("should return filtered users")
            console.log(filteredUsers)
            return filteredUsers
        }


    }

    useEffect(() => {
        console.log(citySelected)
        const temporaryUsers = applyFilter(allUsers);
        console.log(temporaryUsers)
        console.log(temporaryUsers)
        setCurrentUsername('')
        setUsers(temporaryUsers)


    }, [toggleDeutsch, togglePolski, toggleBulgarisch,citySelected,filterOnCity])




    return (

        <div className='row'>
            <div className='col-sm-8'>

                <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
                    <div className='row g-0'>


                        <div className='col-md-8'>
                            <div className='card-body'>
                                <div className="col-md-8"> Deutsch <Slider toggle={toggleDeutsch} handleToggle={handleDeutschToggleChange}></Slider></div>
                                <hr></hr>
                                <div className="col-md-8"> Polnisch <Slider toggle={togglePolski} handleToggle={handlePolskiToggle}></Slider></div>
                                <hr></hr>
                                <div className="col-md-8"> Bulgarisch <Slider toggle={toggleBulgarisch} handleToggle={handleToggleBulgarisc}></Slider></div>
                                <hr></hr>
                                <br></br>
                                <Select
                                    placeholder='Waheln Sie Eine Stadt Aus'
                                    isSearchable

                                    value={idCitySelected ? { "label": idCitySelected, "value": idCitySelected } : null}
                                    options={cityOptions}
                                    onChange={handleCityChange}
                                />


                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </div>









    );





}