import React from 'react'

import { useState } from 'react'

import { requestNewPassword } from '../../services/AuthService'

import { SpinnerLoading } from '../utils/SpinnerLoading';



export const RequestPasswordChange = () => {

    const [username, setUsername] = useState('');
    const [requestSuccess, setRequestSuccess] = useState(false);
    const [requestFailure, setRequestFailure] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    async function handlePasswordRequestSubmit(e) {
        e.preventDefault();
        setIsLoading(true)

        await requestNewPassword(username).then((response) => {
            console.log(response.data)
            setRequestSuccess(true);
            setIsLoading(false)
        }).catch(error => {
            console.error(error);
            setRequestFailure(true);
        })

    }




    return (
        <div className='container'>
            <br /> <br />
            <div className='row mb-3 mt-2'>
                <div className='col-md-12 col-lg-6 offset-lg-3'>
                    <div className='card'>
                        <img class="card-img-top" src={`${process.env.PUBLIC_URL}/forgot-password-illustration.svg`}
                            height="100"
                            width="100"
                            alt='Missing image' />
                        <div className='card-header'>
                            <h3 className='text-center'> Passwort-Änderungscode anfordern </h3>
                        </div>
                        

                        <div className='card-body'>
                            <form>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Username </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='username'
                                            className='form-control'
                                            placeholder='Username eintippen'
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>





                                <div className="col-md-6 offset-md-6">
                                    <div className='form-group mb-3'>
                                        <button className='btn btn-primary' onClick={(e) => handlePasswordRequestSubmit(e)}>Submit</button>

                                    </div>


                                </div>


                            </form>

                            {isLoading &&
                                <SpinnerLoading></SpinnerLoading>
                            }

                            {requestSuccess &&
                                <div className="alert alert-success" role="alert">
                                    <p>The instructions to set your password has been sent to your email. Please check your email.</p>
                                </div>
                            }


                            {requestFailure &&
                                <div className="alert alert-danger" role="alert">
                                    <p>Request denied. The username is invalid. Please check your username</p>
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}
