import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useState, useEffect } from 'react';
import { getTuerInfo } from './ObjectInfo/GetObjectInfo';

import { GetObjectInfo } from './ObjectInfo/GetObjectInfo';

import { ErrorFallback } from './ErrorFallback';

import { ErrorBoundary } from 'react-error-boundary'

import { DisplayAufzug } from '../maps/DisplayAufzug';

import { SpinnerLoading } from './SpinnerLoading';

import { currentContext } from '../../SecurityContext';

import { urlStem } from '../../services/UrlService';




import axios from 'axios';

function formatObject(myObject) {
    return JSON.stringify(myObject)
}

export const ShowMoreInfo = (props) => {

    const mystem=urlStem(currentContext);

    

    const [currentObjektData, setCurrentObjektData] = useState(null)
    const currentId = String(props.utility.id)

    const objecttype = props.utility.objektart.toLowerCase();

    const [cardClass, setCardClass] = useState('card mt-3 shadow p-3 mb-3 bg-body rounded')

    const [butonClass, setButtonClass] = useState("btn btn-primary")



    const [showDetails, setShowDetails] = useState(false)

    const testInfo = { "fahrkartenautomat": false, "summesitzplaetze": 6 }

    const testInfo2 = { "oeffnungszeitenbeschreibung": "4:45-00:30", "rollstuhltauglich": "ja_allereisenden" }

    const metaInfo = { "id": 123, "objektart": "some type", "haltestelle": 12345 }

    const details = { "verbindungsfunktion": "zwischen A und B", "rest": "bla bla" }

    const categoriesWithoutDetails = ["Bahnhof", "Haltestelle", "SEVHaltesteig"]


    const [realtimeAufzugStatus, setRealtimeAufzugStatus] = useState('')

    const [realtimeDisplay,setRealtimeDisplay]=useState('')

    const [isLoading,setIsLoading]=useState(false)






    useEffect(() => {
        const fetchUtilityData = async () => {

            

            console.log("use effect triggered")


            const detailsurl = `${mystem}/bfrk_api/${objecttype}/${currentId}`

            console.log(detailsurl)
            const res = await axios.get(detailsurl);
            const utilityData = res.data
            utilityData.id = props.utility.id
            //   console.log(utilityData)


            setCurrentObjektData(utilityData)
            setIsLoading(false)



        };

        const fetchRealtimeData = async () => {

            console.log("use effect triggered")


            const detailsurl = `${mystem}/bfrk_api/aufzug_livestatus/${currentId}`

            //console.log(detailsurl)
            const res = await axios.get(detailsurl);
            const utilityData = res.data
            console.log(utilityData)
            utilityData.id = props.id
            //   console.log(utilityData)


            setRealtimeAufzugStatus(utilityData)
            setCurrentObjektData(utilityData)
            setIsLoading(false)



        };

        if (showDetails) {

            if (!(objecttype) === "aufzug") {
                console.log("other updation")
                fetchUtilityData()
                setCardClass('card mt-3 shadow p-3 mb-3 text-dark bg-info rounded')
                setButtonClass("btn btn-light")

            }else{
                console.log("aufzug updation running")
                
                fetchRealtimeData()
                setCardClass('card mt-3 shadow p-3 mb-3 text-dark bg-info rounded')
                setButtonClass("btn btn-light")
            }




        } else {
            setCardClass('card mt-3 shadow p-3 mb-3 bg-body rounded')
            setButtonClass("btn btn-primary")

        }






    }
        , [showDetails])

        useEffect(() => {
         console.log("update component")
         setRealtimeDisplay(createAufzugUpdate())

        }, [realtimeAufzugStatus])

        const createAufzugUpdate=()=>{
            if(!(realtimeAufzugStatus.hasOwnProperty('aufzugstatus'))){
                return "keine echtzeit information"
            }else{
                return realtimeAufzugStatus.aufzugstatus;
            }
        }
        

    const handleChange = () => {

        /*  const fetchDataAgain=async ()=>{
 
 
              setCurrentObjektData(null)
               
             const detailsurl=`https://bfrk-kat-api.efa-bw.de/bfrk_api/${objecttype}/${currentId}`
            
             console.log(detailsurl)
             const res = await axios.get(detailsurl);
             const utilityData=res.data
             console.log(utilityData)
             setCurrentObjektData(utilityData)
            
            
             
 
 
 
       }; */

        console.log("button has been clicked")

        setShowDetails(!showDetails)
        setIsLoading(true)
        // console.log(currentObjektData)


    }


    return (

        <div className='row'>
            <div className='col-sm-8'>

                <div className={cardClass}>
                    <div className='row g-0'>
                        <div className='col-md-2'>
                            <div className='d-none d-lg-block'>
                                {props.utility.img ?
                                    <img src={`${process.env.PUBLIC_URL}${props.utility.img}`}
                                        width='80'
                                        height='196'
                                        alt='Missing Image'
                                    />
                                    :
                                    <img src={require('../../Images/BooksImages/questionMark.svg')}
                                        width='80'
                                        height='196'
                                        alt='Missing Image'
                                    />
                                }
                            </div>
                            <div className='d-lg-none d-flex justify-content-center 
                                                   align-items-center'>
                                {props.utility.img ?
                                    <img src={`${process.env.PUBLIC_URL}${props.utility.img}`}
                                        width='80'
                                        height='196'
                                        alt='Missing image'
                                    />
                                    :
                                    <img src={require('../../Images/BooksImages/questionMark.svg')}
                                        width='80'
                                        height='196'
                                        alt='Missing image'
                                    />
                                }
                            </div>
                        </div>





                        <div className='col-md-6'>
                            <div className='card-body'>
                                <h5 className='card-title'>
                                    ObjektId: {props.utility.id}  

                                </h5>
                                <h6>


                                </h6>
                                <div className='card-text'>

                                    <p>Objektart : {props.utility.objektart} </p>
                                    {((objecttype==="aufzug") && showDetails) && 
                                    <p>Aktueller Stand: {realtimeDisplay}</p>
                                    }
                                    {(currentObjektData && showDetails) && <div>

                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallback}>
                                            <GetObjectInfo objektart={objecttype} information={currentObjektData} objectid={currentId}></GetObjectInfo>

                                        </ErrorBoundary>

                                    </div>}

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-center align-items-center'>
                            {!categoriesWithoutDetails.includes(props.utility.objektart) &&
                                <button className={butonClass} onClick={handleChange}>
                                    {showDetails ? "Ausblenden" : "Anzeigen"}
                                </button>
                            }



                        </div>
                    </div>
                </div>

            </div>
        </div>

    );


}
