import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export const Heros = () => {
    return (
        <div>
            <div className='d-none d-lg-block'>
                <div className='row g-0 mt-5'>
                    <div className='col-sm-6 col-md-6'>
                        <div className='col-image-left'></div>
                    </div>
                    <div className='col-4 col-md-4 container d-flex justify-content-center align-items-center'>
                        <div className='ml-2'>
                            <h1 className="h1_rc">Willkommen in der Care-Community</h1>
                            <p className='lead'>
                                Möchten Sie Kontakte knüpfen und Erfahrungen mit anderen Nutzern austauschen? Oder nach den neuesten Veranstaltungen und Sprachkursen suchen und auf dem Laufenden bleiben? Dann sind Sie hier genau richtig.  Registrieren Sie sich jetzt.
                            </p>
                            {/* <a className='btn main-color btn-lg text-white' href='#'>Sign up</a> */}
                            <button className="hp_button">
                                <NavLink className="hp_navlink" to='/User'>
                                    Anmelden
                                </NavLink>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-4 col-md-4 container d-flex 
                        justify-content-center align-items-center'>
                        <div className='ml-2'>
                            <h1 className="h1_rc">Alle wichtigen Informationen an einem Ort</h1>
                            <p className='lead'>
                                Hier findest Du wichtige Anlaufstellen, wo Du Unterstützung in verschiedenen Themen, sortiert nach Kategorien bekommen kannst.
                                <br></br>
                                <br></br>
                                Im Bereich FAQ haben wir häufig gestellte Fragen und Antworten dazu gesammelt. Unter Infos sind relevante Links und mehr Informationen zu den Themen aufgelistet.
                            </p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6'>
                        <div className='col-image-right'></div>
                    </div>
                </div>
            </div>

            {/* Mobile Heros */}
            <div className='d-lg-none'>
                <div className='container'>
                    <div className='m-2'>
                        <div className='col-image-left'></div>
                        <div className='mt-2'>
                        <h1 className="h1_rc">Willkommen in der Care-Community</h1>
                        <h1 className="h1_rc">Willkommen in der Care-Community</h1>
                            <p className='lead'>
                                Möchten Sie Kontakte knüpfen und Erfahrungen mit anderen Nutzern austauschen? Oder nach den neuesten Veranstaltungen und Sprachkursen suchen und auf dem Laufenden bleiben? Dann sind Sie hier genau richtig.  Registrieren Sie sich jetzt.
                            </p>
                            {/* <a className='btn main-color btn-lg text-white' href='#'>Sign up</a> */}
                            <button className="btn main-color btn-lg text-white">
                                <NavLink  to='/User'>
                                    Anmelden
                                </NavLink>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}