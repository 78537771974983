import { check } from 'react-icons-kit/fa/check'
import {eye} from 'react-icons-kit/icomoon/eye'
import { Icon } from 'react-icons-kit'
export const MessageSnippet = ({ message, setCurrentId, currentId }) => {

    const messageId = message.id

    const snippet = message.body.substring(0, 6) + '.....'

    const handleView = () => {
        //console.log(props.activate)
        setCurrentId(messageId);
        window.scrollTo(0,700)

    }
    return (

        <div className='row'>
            <div className='col-sm-8'>

                <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
                    <div className='row g-0'>
                        {(currentId === message.id) &&
                            <div style={{ color: '#E9C46A' }}>
                                <Icon size={32} icon={check} />
                            </div>

                        }

                        <div className='col-md-6'>
                            <div className='card-body'>
                                <span className="d-inline d-md-none">
                                    <small>Subject :  &nbsp;{message.subject}</small>
                                </span>
                                <br></br>
                                <span className="d-inline d-md-none">
                                    <small>Sender :  &nbsp;{message.senderUsername}</small>
                                </span>
                                <span className="d-none d-md-inline">
                                    Subject :  &nbsp; {message.subject}
                                </span>
                                <br></br>

                                <span className="d-none d-md-inline">
                                    Sender :  &nbsp; {message.senderUsername}
                                </span>
                                <h6>


                                </h6>
                                <div className='card-text'>


                                    <p> {snippet}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-center align-items-center'>
                            <button className="btn btn-info" onClick={handleView}><Icon size={20} icon={eye}></Icon></button>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}