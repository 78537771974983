
import { useState, useEffect } from "react"

import { registerAPICall } from "../../services/AuthService"

import { SpinnerLoading } from "../utils/SpinnerLoading"
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { checkCircle } from 'react-icons-kit/feather/checkCircle'

import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    LoadCanvasTemplateNoReload,
    validateCaptcha,
} from "react-simple-captcha";
export const RegiserComponent = () => {



    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);

    const [message, setMessage] = useState('')

    const [city, setCity] = useState('')

    const [deutsch, setDuetsch] = useState(false)
    const [polksi, setPolski] = useState(false)
    const [bulgarian, setBulgarian] = useState(false)

    const [languageStr, setLanugageStr] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const [registerSuccess, setRegisterSuccess] = useState(false)
    const [registerFailure, setRegisterFailure] = useState(false)

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const [agbChecked, setAgbChecked] = useState(false)

    const usernameErrorMessage = 'Der Benutzername muss mindestens 4 Zeichen lang sein und darf keine Sonderzeichen enthalten.'

    const passowordErrorMessage = 'Passort muss mindestens 4 Zeichen lang sein'

    const emailErrorMessage = 'Email ist invalid'

    const messageErrorMessage = 'Bitte geben Sie Ihre Nachricht mit Ihrer OSM ID ein'

    const [cityErrorMessage] = 'Bitte geben Sie die Stadt in Deutschland an, in der Sie leben'

    const captchaErrorMessage='Bitte geben Sie den Wert der Captcha-Anzeige genau ein. Bitte beachten Sie, dass Groß- und Kleinbuchstaben unterschiedlich sind'

    const [usernameError, setUsernameError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)

    const [emailErrot, setEmailError] = useState(false)

    const [messageError, setMessageError] = useState(false)

    const [cityError, setCityError] = useState(false)

    const [userCaptchaValue, setUserCaptchaValue] = useState('')

    const [captChaErrorState, setCaptchaErrorState] = useState(true)

    useEffect(() => {
        loadCaptchaEnginge(10)
    }, [])

    function verifyCaptcha(e){
        e.preventDefault()

        if(validateCaptcha(userCaptchaValue)===true){
            setCaptchaErrorState(false)
        }
        else{
            setCaptchaErrorState(true)
        }
    }


    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }



    const getBrowserName = () => {
        let browserInfo = navigator.userAgent;
        let browser;
        if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
            browser = 'Opera';
        } else if (browserInfo.includes('Edg')) {
            browser = 'Edge';
        } else if (browserInfo.includes('Chrome')) {
            browser = 'Chrome';
        } else if (browserInfo.includes('Safari')) {
            browser = 'Safari';
        } else if (browserInfo.includes('Firefox')) {
            browser = 'Firefox'
        } else {
            browser = 'unknown'
        }
        return browser;
    }


    useEffect(() => {
        const currentBrowser = getBrowserName()
        console.log(currentBrowser)

    }, [])


    const enableSumitButton = () => {
        setIsSubmitDisabled(false)
        console.log("form validation successful")
    }

    const checkFormInput = () => {

        if ((username.length > 0) && (password.length > 0) && (email.length > 0) && (message.length > 0) && (city.length>0)) {

            if (!(usernameError) && !(passwordError) && !(emailErrot) && !(messageError) && !(cityError) &&!(captChaErrorState) && agbChecked ) {
                console.log(captChaErrorState)
                
                setIsSubmitDisabled(false)
            }
            else {
                setIsSubmitDisabled(true)
            }
        }

    }

    useEffect(() => {
        checkFormInput()
    }, [username, password, email, message, city, languageStr, agbChecked, usernameError, passwordError, emailErrot, messageError,captChaErrorState])


    const checkHandler = () => {
        setAgbChecked(!agbChecked)
    }

    const deutschHandler = () => {
        setDuetsch(!deutsch)
    }

    const polskiHandler = () => {
        setPolski(!polksi)
    }

    const bulgarianHandler = () => {
        setBulgarian(!bulgarian)
    }

    function formatGerman() {
        if (deutsch) {
            return 'Deutsch;'
        } else {
            return ''
        }
    }

    function formatBulgarian() {
        if (bulgarian) {
            return 'Bulgarisch;'
        } else {
            return ''
        }
    }

    function formatPolski() {
        if (polksi) {
            return "Polnisch;"
        } else {
            return ''
        }
    }

    useEffect(() => {

        const currentLanguages = formatGerman() + formatPolski() + formatBulgarian()
        setLanugageStr(currentLanguages)


    }, [deutsch, polksi, bulgarian])


    useEffect(() => {
        checkFormInput()


    }, [agbChecked])


    const checkEmail = (emailStr) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    useEffect(() => {
        if ((username.length > 0) && (username.length < 5)) {
            setUsernameError(true)
        }

        if ((username.length >= 5)) {
            setUsernameError(false)
        }


    }, [username])

    useEffect(() => {
        if ((password.length > 0) && (password.length < 5)) {
            setPasswordError(true)
        }

        if ((password.length >= 5)) {
            setPasswordError(false)
        }


    }, [password])


    useEffect(() => {
        if ((email.length > 0)) {
            if (checkEmail(email)) {

                setEmailError(false)

            } else {
                setEmailError(true)
            }

        }




    }, [email])



    useEffect(() => {
        if ((message.length > 0) && (message.length < 5)) {

            setMessageError(true)


        }

        if (message.length > 5) {
            setMessageError(false)
        }




    }, [message])


    useEffect(() => {
        if ((city.length > 0) && (city.length < 3)) {
            setCityError(true)
        }

        if (city.length > 2) {
            setCityError(false)
        }



    }, [city])





    async function handleRegistrationForm(e) {

        e.preventDefault();
        setIsLoading(true)

        console.log("we are registering")
        console.log(isSubmitDisabled)

        const register = { "username": username, "password": password, "email": email, "message": message, "city": city, "languagesKnown": languageStr }

        console.log(register);


        await registerAPICall(register).then((response) => {
            console.log(response.data);
            console.log("success")
            setRegisterSuccess(true)
            //clear all fields if submit successful
            setEmail('')
            setUsername('')
            setPassword('')
            setMessage('')
            setCity('')
            setUserCaptchaValue('')
            setCaptchaErrorState(true)
            setAgbChecked(false)
            setDuetsch(false)
            setPolski(false)
            setBulgarian(false)

            setIsSubmitDisabled(true)

            setIsLoading(false)
        }).catch(error => {
            console.error(error);
            console.log("error")
            setRegisterFailure(true)
            setIsLoading(false)
        })
    }


    return (
        <div className='container'>
            <br /> <br />
            <div className='row mb-3 mt-2'>
                <div className='col-md-12 col-lg-6 offset-lg-3'>
                    <div className='card'>
                        <img class="card-img-top" src={`${process.env.PUBLIC_URL}/networking-illustration.svg`}
                            height="100"
                            width="100"
                            alt='Missing image' />
                        <div className='card-header'>
                            <h3 className='text-center'> registrieren </h3>
                        </div>



                        <div className='card-body'>
                            <form>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Username </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='username'
                                            className='form-control'
                                            placeholder='username'
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        >
                                        </input>
                                    </div>

                                    {(usernameError) &&
                                        <p className="text-danger">{usernameErrorMessage}</p>
                                    }
                                </div>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Email </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='email'
                                            className='form-control'
                                            placeholder='email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        >
                                        </input>
                                        {(emailErrot) &&
                                            <p className="text-danger">{emailErrorMessage}</p>
                                        }
                                    </div>


                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Passwort </label>
                                    <div className='col-md-9'>
                                        <input
                                            type={type}
                                            name='password'
                                            className='form-control'
                                            placeholder='password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        >
                                        </input>
                                        <span class="flex justify-around items-center" onClick={handleToggle}>
                                            <Icon class="absolute mr-10" icon={icon} size={25} />
                                        </span>
                                    </div>

                                    {(passwordError) &&
                                        <p className="text-danger">{passowordErrorMessage}</p>
                                    }
                                </div>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Nachricht </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='message'
                                            className='form-control'
                                            placeholder='Bitte erzählen Sie uns ein wenig über Ihre Interessen'
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                        </input>
                                    </div>

                                    {(messageError) &&
                                        <p className="text-danger">{messageErrorMessage}</p>
                                    }
                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Stadt </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='city'
                                            className='form-control'
                                            placeholder='Bitte sagen Sie uns, in welcher Stadt Sie gerade wohnen'
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                        >
                                        </input>
                                    </div>

                                    {(cityError) &&
                                        <p className="text-danger">{cityErrorMessage}</p>
                                    }
                                </div>

                                <br></br>

                                <br></br>
                                <div>
                                    <p>Spechen Sie eine von den Sprachen?</p>
                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'>  </label>
                                    <div className='col-md-9'>
                                        <input
                                            type="checkbox"
                                            id="checkbox"
                                            checked={deutsch}
                                            onChange={deutschHandler}
                                        />
                                        <label htmlFor="checkbox">  Deutsch </label>
                                    </div>


                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'>  </label>
                                    <div className='col-md-9'>
                                        <input
                                            type="checkbox"
                                            id="checkbox"
                                            checked={polksi}
                                            onChange={polskiHandler}
                                        />
                                        <label htmlFor="checkbox">  Polski </label>
                                    </div>


                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'>  </label>
                                    <div className='col-md-9'>
                                        <input
                                            type="checkbox"
                                            id="checkbox"
                                            checked={bulgarian}
                                            onChange={bulgarianHandler}
                                        />
                                        <label htmlFor="checkbox">  Bulgarisch </label>
                                    </div>


                                </div>

                                <br>
                                </br>
                                <br></br>

                                <div className="row mt-2 mb-2">

                                    <div className="col-md-9 offset-md-3">
                                        <LoadCanvasTemplate></LoadCanvasTemplate>
                                    </div>

                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Captcha </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='city'
                                            className='form-control'
                                            placeholder='Tippen Sie das Captch wert von oben genau ein'
                                            value={userCaptchaValue}
                                            onChange={(e) => setUserCaptchaValue(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                    

                                    <div className="col-3 offset-md-5 mt-3">
                                    <button className="btn btn-primary" onClick={verifyCaptcha}>
                                        <Icon icon={checkCircle} onClick={(e) => verifyCaptcha(e)}></Icon>
                                       <small>Captch Verify</small>
                                    </button>
                                    </div>

                                    



                                    {((captChaErrorState) && userCaptchaValue.length>0) &&
                                        <p className="text-danger">{captchaErrorMessage}</p>
                                    }
                                </div>





                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'>  </label>
                                    <div className='col-md-9'>
                                        <input
                                            type="checkbox"
                                            id="checkbox"
                                            checked={agbChecked}
                                            onChange={checkHandler}
                                        />
                                        <label htmlFor="checkbox"> &nbsp; Ich stimme den Nutzungsbedingungen zu </label>
                                    </div>


                                </div>


                                <div className="col-md-6 offset-md-6">
                                    <div className='form-group mb-3'>
                                        <button className='btn btn-primary' disabled={isSubmitDisabled} onClick={(e) => handleRegistrationForm(e)}>Submit</button>

                                    </div>


                                </div>


                            </form>

                            {isLoading &&
                                <SpinnerLoading></SpinnerLoading>
                            }

                            {registerSuccess &&
                                <div className="alert alert-success" role="alert">
                                    <p>Sie haben sich erfolgreich registriert. Bitte prüfen Sie Ihre E-Mail.</p>
                                </div>
                            }


                            {registerFailure &&
                                <div className="alert alert-danger" role="alert">
                                    <p>Entweder der Benutzername oder Ihre E-Mail oder beide sind nicht verfügbar. Bitte verwenden Sie eine andere Kombination</p>
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </div>


        </div>
    )


}