


export const SchwerpunktDisplay=()=>{

    return (
        <div className="container">
          <div className="row mt-2 mb-2">
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/arbeit-und-gehalt.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Arbeit und Gehalt</h5>
                  <p className="card-text">Eine Beratung über Job und Gehalt kann helfen, Karriereziele zu definieren, eine realistische Einschätzung des eigenen Marktwerts zu erhalten und Verhandlungsfähigkeiten zu verbessern, um ein angemessenes Gehalt zu erzielen.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/seelsorge.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Seelsorge</h5>
                  <p className="card-text">Die seelsorgerische Betreuung bietet zahlreiche Vorteile für Menschen, die Unterstützung in schwierigen Lebensphasen suchen. Sie kann helfen, emotionale und psychologische Belastungen zu bewältigen und neue Perspektiven zu entwickeln, um ein erfülltes Leben zu führen.</p>        
          
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/beratung.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Beratung</h5>
                  <p className="card-text">Die Beratung in Deutschland kann in verschiedenen Bereichen wie Gesundheit, Bildung, Gesellschaft, Arbeit, Wohnen, Gesundheit oder Wirtschaft eingesetzt werden, um wertvolle Einblicke und praktische Ratschläge zu erhalten. Erfahrene Berater können auf die individuellen Bedürfnisse und Herausforderungen eingehen und maßgeschneiderte Lösungen anbieten.</p>        
          
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-2">
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/deutsch-lernen.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Deutsch Lernen</h5>
                  <p className="card-text">Deutschkurse sind eine effektive Möglichkeit, um Deutsch als Fremdsprache zu lernen oder bestehende Kenntnisse zu verbessern. Wir bieten verschiedene Kursangebote, die auf die individuellen Bedürfnisse und Ziele der Lernenden abgestimmt sind, von Einsteigerkursen bis hin zu spezialisierten Kursen für Berufstätige.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/austausch.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Austausch</h5>
                  <p className="card-text">Die Austauschangebote ermöglichen es, wertvolle Kontakte zu knüpfen, Ideen und Erfahrungen miteinander zu teilen, Zugang zu neuen Informationen und potenziellen Beschäftigungsmöglichkeiten zu erhalten und die persönliche Sichtbarkeit und Reputation in der Branche zu verbessern.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/leben-in-deutschland.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Leben in Deutschland</h5>
                  <p className="card-text">Das Leben in Deutschland bietet eine hohe Lebensqualität mit guter Infrastruktur, einem umfassenden Sozialsystem und einer reichen kulturellen Vielfalt. In diesem Zusammenhang möchten wir sicherstellen, dass du dich mit den kulturellen Unterschieden vertraut machst, um erfolgreich in der Arbeitswelt zu agieren, kulturelle Fallstricke zu vermeiden und eine komfortable Lebensqualität zu genießen.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/pflege.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">PflegeKurs</h5>
                  <p className="card-text">Die Arbeit als Betreuungskraft erfordert sowohl eine hohe Fachkompetenz als auch Empathie und Einfühlungsvermögen. Wir unterstützen Dich mit aktuellen Informationen und Angeboten zur Weiterbildung in Deinem Beruf.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
              <img  className="card-img-top" src={`${process.env.PUBLIC_URL}/freizeit.jpg`}/>
                <div className="card-body">
                  <h5 className="card-title">Freizeitgestaltung</h5>
                  <p className="card-text">Deutschland bietet eine Vielzahl von Freizeitmöglichkeiten und Sehenswürdigkeiten, von malerischen Landschaften und historischen Städten bis hin zu erstklassigen Museen und kulturellen Veranstaltungen.</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      );
}