

/* export const getTuerInfo=(input)=>{
    const a=first(input);
    return a.toLowerCase();
} */

/* function first(a){
    return a+a;
}
 */



function formatBoolen(input){
    if(input===true){
        return "Ja"
    }
    else{
        return "Nein"
    }
}

function formatBahnsteig(information){
    var fahrkartenautomat=formatBoolen(information.fahrkartenautomat);
    var sitzplatze=String(information.summesitzplaetze)
    var string1="Fahrkartenautomat : " +fahrkartenautomat
    var string2="Sitzplatze : "+sitzplatze
    return [string1,string2]
}

function formatHaltesteig(information){
    var bodenindikator=formatBoolen(information.bodenindikator_leitstreifen)
    var rampe=formatBoolen(information.rampe)
    var string1="Bodenindikator Leitstreifen : "+bodenindikator
    var string2="Rample : "+ rampe
    return [string1,string2]
}


function formatAufzug(information){
    var verbindungsfunktion=information.verbindungsfunktion
    var tuerbreite_cm=String(information.tuerbreite_cm)

    
    
    var string1="Verbindungsfunktion : "+verbindungsfunktion
    var string2="Türbreite : "+ tuerbreite_cm
    return [string1,string2]
}

function formatEngstelle(information){
    var durchgangsbreite_cm=String(information.durchgangsbreite_cm)
    var bewegflaeche_cm=String(bewegflaeche_cm)
    var string1= "Durchgangsbreite (cm) : "+durchgangsbreite_cm
    var string2= "Bewegfläche (cm) "+bewegflaeche_cm
    return [string1,string2]
}

function formatfahrradanlage(information){
    var wegzuranlageanfahrbar=formatBoolen(information.wegzuranlageanfahrbar)
    var kostenpflichtig=formatBoolen(information.kostenpflichtig)

    var string1="Weg zur Anlage anfahrbar : "+wegzuranlageanfahrbar
    var string2= "Kostenpflichtig : "+kostenpflichtig

    return [string1,string2]

}

function fortmatGleisquerung(information){
    var verbindungsfunktion=information.verbindungsfunktion
    var string1= "Verbindungsfunktion : "+verbindungsfunktion
    return [string1]


}

function formatinfostelle(information){
    var infostellenart=information.infostellenart
    var stufenfrei=formatBoolen(information.stufenfrei)
    
    var string1= "Infostelleart : "+infostellenart
    var string2= "Stufenfrei : "+stufenfrei
    return [string1,string2]

}


function formatKatenautomat(information){
    var entwertervorhanden=formatBoolen(information.entwertervorhanden)
    var string1= " Entwertervorhanden : "+entwertervorhanden
    return [string1]
}

function formatParkplatz(information){
    var behindertenstellplaetze= String(information.behindertenstellplaetze)
    var stellplaetzgesamt=String(information.stellplaetzgesamt)

    var string1= "Behindertenstellplätze : "+behindertenstellplaetze

    var string2= "Stellplätze insgesamt: "+stellplaetzgesamt
    
    return [string1,string2]
}

function formatRampe(information){
    var breite_cm=String(information.breite_cm)
    var querneigung_prozent=String(information.querneigung_prozent)
    var verbindungsfunktion=information.verbindungsfunktion
    var laengsneigung_prozent=String(information.laengsneigung_prozent)

    var string1="Breite (cm) : "+breite_cm
    var string2="Quereigung Prozent : "+querneigung_prozent
    var string3="Verbindungsfuktion : "+verbindungsfunktion
    var string4="Längseigung Prozent : "+laengsneigung_prozent
    return [string1,string2,string3,string4]

}

function formatRolltreppe(information){

    var fahrtrichtung=information.fahrtrichtung
    var wechselndeRichtung=formatBoolen(information.wechselndeRichtung)
    var laufzeit_sek=String(information.laufzeit_sek)
    var verbindungsfunktion=information.verbindungsfunktion

    var string1= "Fahrichtung : "+fahrtrichtung
    var string2=  "Wechselrichtung : "+wechselndeRichtung
    var string3=  "Laufzeit (Sekunde) : "+laufzeit_sek
    var string4= "Verbindungsfunktion : "+verbindungsfunktion
    return [string1,string2,string3,string4]

    
}

function formatStationsplan(information){
    var planakustisch=formatBoolen(information.planakustisch)
    var bodenindikatorart=information.bodenindikatorart
    var plantaktil=formatBoolen(information.plantaktil)

    var string1="Planakustisch : "+planakustisch
    var string2="Bodenindikatorenart : "+bodenindikatorart
    var string3="Plantaktil : "+plantaktil
    
    return [string1,string2,string3]
}

function formatTaxistand(information){
    var latitude=String(information.lat)
    var longitude=String(information.lon)

    var string1= "Latitude : "+latitude
    var string2="Longitude : "+longitude

    return [string1,string2]
    
}


function formatToilette(information){
    var oeffnungszeitenbeschreibung=String(information.oeffnungszeitenbeschreibung)
   
    //  var rollstuhulInfo=information.rollstuhltauglich.split("_")
    var rollstuhltauglich=information.rollstuhltauglich.split("_")[0]
    console.log(rollstuhltauglich)

    var string1="Offungszeiten : "+oeffnungszeitenbeschreibung
    var string2= "Rollstuhltauglich : "+rollstuhltauglich

    return [string1,string2]
    
	
}

function formatTreppe(information){
    var stufenanzahl=String(information.stufenanzahl)
    var stufenhoehe_cm=String(information.stufenhoehe_cm)
    var verbindungsfunktion=information.verbindungsfunktion

    var string1="Stufenanzahl : "+stufenanzahl
    var string2="Stufenhöhe (cm) : "+stufenhoehe_cm
    var string3="Verbindunsfunktion : "+verbindungsfunktion

    return [string1,string2,string3]

   
}


function formatTuer(information){
    var tuerart=information.tuerart
    var oeffnungsart=information.oeffnungsart

    var string1= "Türart : "+tuerart
    var string2=  "Öffungsart : "+oeffnungsart
    return [string1,string2]
  
}

function formatVerkaufsstelle(information){
    var stufenfrei=formatBoolen(information.stufenfrei)
    var verkaufsstellenart=information.verkaufsstellenart
    var string1="Stufenfrei : "+stufenfrei
    var string2=" Verkaufsstelleart : "+verkaufsstellenart
    return [string1,string2]
    

    
	
}

function formatWeg(information){
    var breite_cm=String(information.breite_cm)
    var beleuchtet=formatBoolen(information.beleuchtet)
    var verbindungsfunktion=information.verbindungsfunktion

    var string1= "Breite (cm) : "+breite_cm
    var string2= "Beleuchtet : "+beleuchtet
    var string3= "Verbindungsfukntion : "+verbindungsfunktion
    return [string1,string2,string3]
}

function formatInformationsstelle(information){

    var stufenfrei=formatBoolen(information.stufenfrei)
    var infostellenart=information.infostellenart
    var string1="Stufenfrei : "+stufenfrei
    var string2=" Informationsstelleart : "+infostellenart
    return [string1,string2]

}





export const GetObjectInfo=(props)=>{

    console.log("from format object")
    
    console.log(props.information)
    console.log(props.objektart)
    var details
    

    try{

        

        switch(props.objektart){
            case  'haltestelle': 
                details= formatHaltesteig(props.information)
                break;
            case 'bahnsteig':
                details=formatBahnsteig(props.information)
                break;
            case 'aufzug':
                details=formatAufzug(props.information)
                break;  
                
            case 'engstelle':
                details=formatEngstelle(props.information)
                break;
                
            case 'fahrradanlage':
                details=formatfahrradanlage(props.information)
                break;
            case 'gleisquerung':
                details=fortmatGleisquerung(props.information)
                break;
            case 'infostelle':
                details=formatinfostelle(props.information)
                break;
            case 'kartenautomat':
                details=formatKatenautomat(props.information)
                break;
            case 'parkplatz':
                details=formatParkplatz(props.information)
                console.log("ja parkplatz")
                console.log(details)
                break;
            
            case 'rampe':
                details=formatRampe(props.information)
                break;
            case 'rolltreppe':
                details=formatRolltreppe(props.information)  
                break;
            case 'stationsplan':
                details=formatStationsplan(props.information)
                break;
            case 'taxistand':
                details=formatTaxistand(props.information)
                break;
            case 'toilette':
                details=formatToilette(props.information)
                break;
            case 'treppe':
                details=formatTreppe(props.information)
                break;
    
            case 'tuer':
                details=formatTuer(props.information)
                break;
            case 'verkaufsstelle':
                details=formatVerkaufsstelle(props.information)
                break;   
            case 'weg':
                
                details=formatWeg(props.information)  
                break;
            case 'informationsstelle':
                details=formatInformationsstelle(props.information)
                break;           
    
    
    
    
    
    
            default:
                details=["keine information","keine information"]        
    
        }


    }catch(err){

        details=["something went wrong","Please try again later"]

    }
   
    return(
        <>
        {details.map((d,index)=>(
            <p key={index}>{d}</p>
        ))}
        </>
    );
    
}