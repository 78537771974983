


function getUserLangs(text){
    //console.log(text)
    const languageArray=text.split(";");
    return languageArray.slice(0,languageArray.length-1)
   }
function formatUser(user,Users){
    console.log(user)
 //const objIndex = Users.findIndex(obj => obj.username == user.username);
  const userLangs=user.languageStr
  //console.log(userLangs)
  const userLangArray=getUserLangs(userLangs);
  user.languages=userLangArray;
  
  return user
}

export function formatAllUsers(Users){
    //console.log(Users)
 Users.map((u)=>{
   const objIndex=Users.findIndex((obj)=>obj.username===u.username)
   Users[objIndex]=formatUser(u,Users)
 })
 return Users
}

function userSpeaksLang(user,lang){
  if(user.languages.includes(lang)){
   return true
  }else {
    return false
  }
  
}

function getAllLangSpeakers(Users,langStr){
   const LangSpeakers=[]
   Users.map((u)=>{
      if(userSpeaksLang(u,langStr)){
        LangSpeakers.push(u)
      }
      
   })
   
   return LangSpeakers;

}


export function getUsersSpeakingLang(Users,langstr){
    return getAllLangSpeakers(Users,langstr)
}


   
   