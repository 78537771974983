import { ErrorBoundary } from 'react-error-boundary'

export const ErrorFallback=({ error, resetErrorBoundary })=> {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
     
      
    </div>
  )
}