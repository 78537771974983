

export const Veranstaltung=(props)=>{

    const dummyVeranstaltung={


    
    "title":"Deutschkurs",
    
    "description": "This is a dummy event",
    "tragerImg":"someImg",

     "dateAndTime":"2023-12-31 15:30:00",

     "lat":51.223,
     "lon":6.0800,
     "streetNameNumber": "Schillerstr 100",
     "postalCode":12345,
     "cityName":"Aachen"

    }

 

    return(

        <div className="row">

            <div className="col-md-4 bg-light ">
                <div className="card mt-3 shadow p-3 mb-3 bg-light rounded">
                    <div className="card-body">

                        <h2 className="h2_rc">
                            {dummyVeranstaltung.title}
                        </h2>
                        <p>{dummyVeranstaltung.dateAndTime}</p>
                        <br></br>
                        <p>{dummyVeranstaltung.streetNameNumber} &nbsp;{dummyVeranstaltung.postalCode}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}