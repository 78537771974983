import { check } from 'react-icons-kit/fa/check'
import {eye} from 'react-icons-kit/icomoon/eye'
import { Icon } from 'react-icons-kit'
import {user} from 'react-icons-kit/icomoon/user'
import { userPlus } from 'react-icons-kit/icomoon'
export const UserSnippet = ({ user, setCurrentUsername, currentUsername }) => {

    const username = user.username
    console.log(username)
    const city=user.city

    

    const handleView = () => {
        //console.log(props.activate)
        setCurrentUsername(username);

    }
    return (

        <div className='row'>
            <div className='col-sm-8'>

                <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
                    <div className='row g-0'>
                        {(currentUsername === user.username) &&
                            <div style={{ color: '#E9C46A' }}>
                                <Icon size={32} icon={check} />
                            </div>

                        }

                        <div className='col-md-6'>
                            <div className='card-body'>
                                <span className="d-inline d-md-none">
                                    <small>Stadt :  &nbsp;{user.city}</small>
                                </span>
                                <span className="d-none d-md-inline">
                                    Stadt :  &nbsp; {user.city}
                                </span>
                                <br></br>

                                <span className="d-inline d-md-none">
                                    <small>Username :  &nbsp;{user.username}</small>
                                </span>
                                <span className="d-none d-md-inline">
                                    Username :  &nbsp; {user.username}
                                </span>

                                <br></br>
                                <br></br>

                                <span className="d-inline d-md-none">
                                    <small>Sprachen :  &nbsp;{user.languages.toString()}</small>
                                </span>
                                <span className="d-none d-md-inline">
                                    Sprachen :  &nbsp; {user.languages.toString()}
                                </span>
                                <h6>


                                </h6>
                                <div className='card-text'>


                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-center align-items-center'>
                            <button className="btn btn-info" onClick={handleView}><Icon size={20} icon={userPlus}></Icon></button>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}