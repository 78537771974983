import axios  from "axios"

const template = {
    "type": "Feature",
    "properties": {
        "@id": "node/344125432",
        "leisure": "amusement_arcade",
        "name": "千葉レジャーランド",
        "name:en": "Chiba Leisure Land",
        "sport": "bowls"
    },
    "geometry": {
        "type": "Point",
        "coordinates": [
            140.1167298,
            35.7986974
        ]
    },
    "id": "node/344125432"
}


export const makeGeoJson = (stationArray) => {
    const FeatureCollection = []
    stationArray.map((s) => {
        const currentFeature = makeFeature(s)
        //console.log(currentFeature)
        FeatureCollection.push(currentFeature)

    })

    const myGeojson = {
        "type": "FeatureCollection",
        "generator": "overpass-ide",
        "copyright": "The data included in this document is from www.openstreetmap.org. The data is made available under ODbL.",
        "timestamp": "2023-02-07T09:21:34Z",
        "features": FeatureCollection
    }

    //console.log(myGeojson)

    return myGeojson
}


export const formatHalstelle = (haltestelleObject) => {


    const stationName = haltestelleObject.name + " " + haltestelleObject.gemeinde

    const haltestelleFeature = {
        "id": haltestelleObject.dhid,
        "lat": haltestelleObject.lat,
        "lon": haltestelleObject.lon,
        "stationName": stationName,
        "objektart": haltestelleObject.objektart,
        "oevart":haltestelleObject.oevart
    }

    /* console.log(haltestelleFeature)

    console.log(makeFeature(haltestelleFeature)) */

    return haltestelleFeature
}

export const makeFeature = (haltestelle) => {
    const geometry = {
        "type": "Point",
        "coordinates": [
            haltestelle.lon,
            haltestelle.lat

        ]

    }

    const properties = {

        "@id": haltestelle.id,
        "objektart": haltestelle.objektart,
        "oevart":haltestelle.oevart,
        "name:en": haltestelle.stationName


    }





    const myFeature = { "type": "Feature", "properties": properties, "geometry": geometry, "id": haltestelle.id }

    //console.log(myFeature)

    return myFeature
}


export const getAllKreisOPNV = () => {

    let allKreiseOPNV

    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://bfrk-kat-api.efa-bw.de/bfrk_api/kreise?oevart=O',

        

    };

    return axios.request(config)


    

    





}


export function sliceByCategory(category,utilitiesData) {
    const categorySlice = []
    utilitiesData.map((m) => {
      if (m.objektart === category) {
        categorySlice.push(m)
      }
    })
    return categorySlice
  }