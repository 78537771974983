import { useState } from "react";

import { LoginComponent } from "./LoginComponent";

import { RegiserComponent } from "./RegisterComponent";

import { ActivateAccount } from "./ActivateAccount";

import { ResetPassword } from "./ResetPassword";

import { RequestPasswordChange } from "./RequestPasswordChange";



import { useEffect } from "react";

import Select from 'react-select'



export const UserServices = () => {

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    

    const [serviceSelected, setServiceSelected] = useState('')

    const anliegen = [{ "label": "Anmelden", "value": "login" }, { "label": "registrieren", "value": "register" }, { "label": "Aktiverieren Aktivieren Sie Ihr Konto", "value": "activate" }, { "label": "Passwort Vergessen", "value": "forgot" }, { "label": "Passwort Änderen", "value": "reset" }]


    useEffect(() => {
        console.log(serviceSelected)


    }, [serviceSelected])



    const handleTaskChange = (e) => {


        setServiceSelected(e.value);


    }




    return (
        <div className="container">
            

                <div className="row mb-3 mt-2">

                    <div className='col-md-6 offset-md-3'>
                        <Select
                            placeholder='Wahlen Sie Ihre Anliegen'
                            isSearchable
                            // value={kreisSelected}
                            options={anliegen}
                            onChange={handleTaskChange}
                        />
                    </div>
                </div>

                <br></br>
                <br></br>

                {(serviceSelected === "login") &&
                    <LoginComponent></LoginComponent>
                }

                {(serviceSelected === "register") &&
                    <RegiserComponent></RegiserComponent>

                }

                {(serviceSelected === "forgot") &&

                    <RequestPasswordChange></RequestPasswordChange>

                }

                {(serviceSelected === "reset") &&
                    <ResetPassword></ResetPassword>
                }


                {(serviceSelected === "activate") &&

                    <ActivateAccount></ActivateAccount>

                }


            


        </div>

    );
}