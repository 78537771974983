import axios from 'axios';
import { useEffect, useState } from 'react';

import { markasComplete } from '../../services/ResistrictedCalls';
export const ShowOSMObject = (props) => {

    const [currentStatus, setCurrentStatus] = useState(props.status);

    const [updateFailure, setUpdateFailure] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const id = props.osmid;

    useEffect(() => {
        console.log("current status changed")


    }, [currentStatus])


    async function handleChange() {

        console.log(id);

        await markasComplete(id).then((response) => {
            console.log("marked as complete function success")
            setUpdateSuccess(true)
            setCurrentStatus("complete")
        }).catch(error => {
            console.error("error");
            setUpdateFailure(true);

        })



        console.log("Marked as complete");
    }
    return (

        <div className='row'>
            <div className='col-sm-8'>

                <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
                    <div className='row g-0'>
                        <div className='col-md-2 '>

                            <div className='d-none d-lg-block'>

                                {(currentStatus === "complete") &&
                                    <img src={`${process.env.PUBLIC_URL}/completed.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }

                                {(currentStatus === "incomplete") &&
                                    <img src={`${process.env.PUBLIC_URL}/edit.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }




                            </div>


                            <div className='d-lg-none d-flex justify-content-center 
                                                   align-items-center ml-3 mr-3'>

                                {(currentStatus === "complete") &&
                                    <img src={`${process.env.PUBLIC_URL}/completed.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }

                                {(currentStatus === "incomplete") &&
                                    <img src={`${process.env.PUBLIC_URL}/edit.svg`}
                                        width='123'
                                        height='196'
                                        alt='Missing Image'
                                    />

                                }




                            </div>
                        </div>
                        <div className='col-md-6 '>
                            <div className='card-body'>
                                <h5 className='card-title'>
                                    ObjektId: {props.osmid}

                                </h5>
                                <h6>


                                </h6>
                                <p className='card-text'>

                                    <p>objektart : {props.objektart}</p>
                                    <p> Status : {currentStatus}</p>
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-center align-items-center'>

                            {currentStatus === "incomplete" &&
                                <button className="btn btn-primary" onClick={handleChange}>
                                    Als abgeschlossen markieren
                                </button>
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}