import { useEffect, useState } from "react";
import { Icon } from 'react-icons-kit'
import { Checkmark } from 'react-checkmark'

import { ic_send } from 'react-icons-kit/md/ic_send'
import { ic_delete_forever } from 'react-icons-kit/md/ic_delete_forever'
import { user_square } from 'react-icons-kit/ikons/user_square'
import { ic_check_circle_twotone } from 'react-icons-kit/md/ic_check_circle_twotone'
import { arrowRightThick } from 'react-icons-kit/typicons/arrowRightThick'

import { getLoggedInUser } from "../../../services/AuthService";

import { replyTo } from "../../../services/ResistrictedCalls";

import { undo2 } from 'react-icons-kit/icomoon/undo2'





export const MessageToUser = (props) => {

    const [replySuccess, setReplySuccess] = useState(false)

    const [replyFailure, setReplyFailure] = useState(false)

    console.log(replySuccess)

    useEffect(() => {
      window.scrollTo(0,0)
    
      
    }, [])
    






    const [requestReply, setRequestReply] = useState(false);

    const [reply, setReply] = useState('')

    async function handleReplySubmit() {
        console.log(reply)

        const messageObject = {};
        messageObject.senderUsername = getLoggedInUser()
        messageObject.recieverUsername = props.reciever
        messageObject.body = reply
        messageObject.subject = "Nachricht von " + props.reciever

        console.log(messageObject)

        await replyTo(messageObject).then((response) => {
            console.log("success")
            setReplySuccess(true)
            setReply('')
        }).catch(error => {
            console.log("error")
            setReplyFailure(true)
        })
    }

    const handleDeltete = () => {
        setReply('')
    }

    const handleUndo = () => {

        props.setCurrentUser('')

    }

    useEffect(() => {
        console.log("clear screen")
    }, [reply])


    useEffect(() => {
        setTimeout(() => {
            // Your JavaScript statement to be executed after 5 seconds
            setReplySuccess(false)
        }, 5000);
    }, [replySuccess])





    return (

        <div>

            <p><Icon icon={user_square}></Icon> &nbsp;&nbsp; {props.sender}  </p>


            {/*  <div>
                {sampleText.split("\n").map((i, key) => {
                    return <div key={key}>{i}</div>;
                })}
            </div> */}

            <br></br>
            <br></br>

            {(props.sender.length === 0) &&
                <div>
                    Bitte wählen Sie einen Benutzer, um eine Nachricht zu senden
                </div>

            }

            {(props.sender.length > 0) &&

                <div>
                    <div className="form-group">
                        <h6>Schreiben an: {props.reciever} </h6>
                        <textarea
                            className="form-control"
                            value={reply}
                            rows="5"
                            onChange={(event) => {
                                setReply(event.target.value)
                            }}
                            placeholder="Tipped Sie hier Ihre Nachricht..."
                        />
                    </div>

                    <br></br>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                            <button className="btn btn-primary" onClick={handleReplySubmit}><Icon icon={ic_send}></Icon></button>
                            </div>
                            <div className="col">
                            <button className="btn btn-primary">
                                <Icon icon={ic_delete_forever} onClick={handleDeltete}></Icon>

                            </button>
                            </div>
                            <div className="col">
                            <button className="btn btn-primary">
                                <Icon icon={undo2} onClick={handleUndo}></Icon>

                            </button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">

                        <br></br>
                        <br></br>
                        {replySuccess &&

                            <p>
                                <Checkmark size='28px' color='#AAFF00' />
                            </p>


                        }

                    </div>
                </div>

            }



        </div>



    );
    ;


}