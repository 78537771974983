import axios from 'axios';

import { useState, useEffect } from 'react';



import { SpinnerLoading } from '../utils/SpinnerLoading'

import { currentContext } from '../../SecurityContext';

import { urlStem } from '../../services/UrlService';


export const DisplayAufzug = (props) => {

    const mystem=urlStem(currentContext)

    const [aufzugImage, setAufzugImage] = useState('questionMark.svg')


    const currentId = props.id

    const [currrentObjektData, setCurrentObjektData] = useState({})

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        
        const fetchUtilityData = async () => {

            console.log("use effect triggered")


            const detailsurl = `${mystem}/bfrk_api/aufzug_livestatus/${currentId}`

            //console.log(detailsurl)
            const res = await axios.get(detailsurl);
            const utilityData = res.data
            utilityData.id = props.id
            //   console.log(utilityData)


            setCurrentObjektData(utilityData)
            setIsLoading(false)



        };

        fetchUtilityData();
       







    }
        , [])



    useEffect(() => {

        //console.log(currrentObjektData)
        getAufzugPicture(currrentObjektData)

    }, [currrentObjektData])


    useEffect(() => {

    }, [aufzugImage])


    const getAufzugPicture = (currrentObjektData) => {
        console.log(currrentObjektData)
        if (currrentObjektData.aufzugstatus === "ACTIVE") {
            console.log("aufuig active")
            setAufzugImage("aufzug.svg")

        }

        if (currrentObjektData.aufzugstatus === "INACTIVE") {
            setAufzugImage("aufzug_inactive.svg")

        }

        /*  else{
             console.log("nothing found")
             setAufzugImage("questionMark.svg")
         } */

    }

   if(isLoading) return <SpinnerLoading></SpinnerLoading>




    return (


       

        <div className='row'>
            <div className='col-sm-8'>

                <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
                    <div className='row g-0'>
                        <div className='col-md-2'>
                            <div className='d-none d-lg-block'>


                                <img src={`${process.env.PUBLIC_URL}/${aufzugImage}`}
                                    width='30'
                                    height='30'
                                    alt='Missing Image'
                                />








                            </div>
                            <div className='d-lg-none d-flex justify-content-center 
                                                   align-items-center'>



                                <img src={`${process.env.PUBLIC_URL}/${aufzugImage}`}
                                    width='30'
                                    height='30'
                                    alt='Missing Image'
                                />






                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card-body'>
                                <h5 className='card-title'>
                                    ObjektId: {props.id}

                                </h5>
                                <h6>


                                </h6>
                                <p className='card-text'>

                                    <p>Status : {currrentObjektData.aufzugstatus}</p>
                                    {!(currrentObjektData.hasOwnProperty('aufzugstatus')) &&
                                        <p className='bg-warning text-dark'> Keine Echtzeitinformation vorhanden</p>
                                    }
                                    <p> Verbindungsfunktion :</p>
                                    <p> {currrentObjektData.verbindungsfunktion}</p>

                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-center align-items-center'>



                        </div>
                    </div>
                </div>

            </div>
        </div>


         

       


        

        
                                
        
    );


}