

import { useEffect, useState } from "react";
import { getLoggedInUser } from "../../../services/AuthService";
import { SpecificMessage } from "./SpecificMessage";

import messages from './data/messages.json'
import { MessageSnippet } from "./MessageSnippet";

import { Pagination } from "../../utils/Pagination";

import { getMessages } from "../../../services/ResistrictedCalls";

import { SpinnerLoading } from "../../utils/SpinnerLoading";



export const Inbox = () => {

  const username = getLoggedInUser();

  const [loggedInUserMessages, setLoggedInUserMessages] = useState([])

  const [Usermessages,setUserMessages]=useState([])

  const [isLoading,setIsLoading]=useState(true)

  console.log(username)

  const [currrentId, setCurrentId] = useState(0);

  const [currentBody, setCurrentBody] = useState('')

  const [currentSubject,setCurrentSubject]=useState('')

  const [currentSender, setCurrentSender] = useState('')


  useEffect(() => {
    

    const fetchMessages = async () => {

      const res = await getMessages(username);
      const allMessages = res.data;
      /* const allKreisLabels=[]
      allKreise.map((m)=>{
       allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
      }); */

      console.log(allMessages)
      allMessages.sort((a,b)=>b.id-a.id)

      setLoggedInUserMessages(allMessages)
      setUserMessages(allMessages)
      setIsLoading(false)
    };

    fetchMessages();
    
  }, [])


  useEffect(() => {
    console.log(loggedInUserMessages.length)
    console.log(Usermessages.length)
    console.log("load again")
  
    
  }, [Usermessages,loggedInUserMessages])
  

 /*  console.log(messages)

  const Usermessages = []
  Usermessages.push(messages[0])
  Usermessages.push(messages[1])
  Usermessages.push(messages[2])
  Usermessages.push(messages[3])
  Usermessages.push(messages[4])
  Usermessages.push(messages[5]) */


  function getMessageByMessageId(messages, messageId) {
    return messages.find(x => x.id === messageId)
  }


  //pagination related stuff. Ugly readl till comment indicating the end of pagintion//
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(5);
  const [totalAmountOfMessages, setTotalAmountOfMessages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  function startIndex(cp, perPage) {
    var s
    if (cp === 1) {
      s = 0
    } else {
      s = (cp - 1) * perPage
    }
    return s
  }
  function endIndex(cp, startIndex, totalPages, perPage, allData) {
    var e

    if (cp === totalPages) {
      e = allData.length 
    } else {
      e = startIndex + perPage
    }
    return e
  }



  function mySlice(s, e, A) {
    if (s === e) {
      return [A[e]]
    } else {
      return A.slice(s, e)
    }
  }

  useEffect(() => {
    const fetchUtilities = () => {


      var totalMessages = Usermessages.length
      var totalPages = Math.ceil(totalMessages / 5)
      var s = startIndex(currentPage, messagesPerPage)
      var e = endIndex(currentPage, s, totalPages, messagesPerPage, Usermessages)

      console.log(s)
      console.log(e)

      setTotalAmountOfMessages(Usermessages.length)
      setCurrentMessages(mySlice(s, e, Usermessages))
      // console.log(mySlice(s,e,utilitiesData))
      setTotalPages(Math.ceil(totalMessages / 5))
      /* const res = await axios.get(baseurl);
      const pagedRes=await axios.get(`${baseurl}?_page=${currentPage}&_limit=5`);
      setBooks(pagedRes.data);
      setTotalAmountOfBooks(res.data.length);
      setTotalPages(Math.ceil(res.data.length/5)); */
    };

    fetchUtilities();
  }, [currentPage,Usermessages]);


  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  let lastItem = messagesPerPage * currentPage <= totalAmountOfMessages ?
    messagesPerPage * currentPage : totalAmountOfMessages;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  /* console.log(String(currentMessages.length) + "total number of utilities")
  console.log(String(currentPage) + " current page")
  console.log(totalPages) */


  //end of pagination relatd stuff



  useEffect(() => {
    console.log("active message changed")
    console.log(currrentId)
    if (currrentId > 0) {
      var clickedMessage = getMessageByMessageId(Usermessages, currrentId)
      setCurrentBody(clickedMessage.body)
      setCurrentSender(clickedMessage.senderUsername)
      setCurrentSubject(clickedMessage.subject)
      console.log(clickedMessage.body)
    }

  }, [currrentId])


  if(isLoading) return <SpinnerLoading></SpinnerLoading>

  return (

    <div className='containter'>
      <div class="row equal-height-row 
                        d-flex flex-wrap">
        <div class="col-md-12 col-lg-6 offset-lg-3 offset-md-1 bg-light 
                            rounded p-4 d-flex 
                            flex-column 
                            justify-content-between">
          <div>
            <h2>Ihre Nachrichten</h2>

            {currentMessages.map(m => (
              <MessageSnippet key={m.id} message={m} currentId={currrentId} setCurrentId={setCurrentId}></MessageSnippet>

            ))}

            {totalPages > 1 &&
              <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}></Pagination>
            }








          </div>
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3 offset-lg-1 bg-light rounded 
                            p-4 d-flex flex-column 
                            justify-content-between">
          <div>

            {currrentId === 0 &&

              <SpecificMessage body={"Bite Wahlen ein Message zur Preview"} sender={''} subject={''}></SpecificMessage>



            }


            {!(currrentId === 0) &&


              <SpecificMessage body={currentBody} sender={currentSender} subject={currentSubject}></SpecificMessage>



            }











          </div>
        </div>

      </div>



    </div>
  );
}