

import { useEffect, useState } from "react";
import { getLoggedInUser } from "../../../services/AuthService";
import { MessageToUser } from "./MessageToUser";


import { UserSnippet } from "./UserSnippet";

import { Pagination } from "../../utils/Pagination";



import { SpinnerLoading } from "../../utils/SpinnerLoading";

import { getAllAdmins } from "../../../services/ResistrictedCalls";
import { getAllUsers } from "../../../services/ResistrictedCalls";

import { getUsersSpeakingLang } from "./UserUtilityFunctions";

import { formatAllUsers } from "./UserUtilityFunctions";

import { UserFilter } from "./UserFilter";



const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};



export const AllUsers = () => {

  const username = getLoggedInUser();



  const [isLoading, setIsLoading] = useState(true)

  console.log(username)

  const [currrentUsername, setCurrentUsername] = useState('');

  const [currentCity, setCurrentCity] = useState('')

  const [allUsers, setAllUsers] = useState([])

  const [fitlerSubset, setFilterSubset] = useState([])

  const attentionMessage='Bite Wahlen ein Nutzer zur Vernetzen'

  const [currentWidth,setCurrenWidth]=useState(useWindowDimensions().width)




  useEffect(() => {


    const fetchUsers = async () => {

      const resUsers = await getAllUsers();
      const allUsers = resUsers.data;
      /* const allKreisLabels=[]
      allKreise.map((m)=>{
       allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
      }); */

      //console.log(resUsers)
      const formattedUsers = formatAllUsers(JSON.parse(JSON.stringify(resUsers.data)))
      //console.log(formattedUsers)
      setAllUsers(formattedUsers)
      setFilterSubset(formattedUsers)

      setIsLoading(false)

    };

    fetchUsers();

  }, [])


  useEffect(() => {

    //console.log("load again")


  }, [allUsers, fitlerSubset])


  /*  console.log(messages)
 
   const Usermessages = []
   Usermessages.push(messages[0])
   Usermessages.push(messages[1])
   Usermessages.push(messages[2])
   Usermessages.push(messages[3])
   Usermessages.push(messages[4])
   Usermessages.push(messages[5]) */


  function getUserByUsername(userCollection, username) {
    return userCollection.find(x => x.username === username)
  }


  //pagination related stuff. Ugly readl till comment indicating the end of pagintion//
  const [currentUsers, setCurrentUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(5);
  const [totalAmountOfUsers, setTotalAmountOfUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  function startIndex(cp, perPage) {
    var s
    if (cp === 1) {
      s = 0
    } else {
      s = (cp - 1) * perPage
    }
    return s
  }
  function endIndex(cp, startIndex, totalPages, perPage, allData) {
    var e

    if (cp === totalPages) {
      e = allData.length
    } else {
      e = startIndex + perPage
    }
    return e
  }



  function mySlice(s, e, A) {
    if (s === e) {
      return [A[e]]
    } else {
      return A.slice(s, e)
    }
  }

  useEffect(() => {
    const fetchUtilities = () => {


      var totalUsers = fitlerSubset.length
      var totalPages = Math.ceil(totalUsers / 5)
      var s = startIndex(currentPage, messagesPerPage)
      var e = endIndex(currentPage, s, totalPages, messagesPerPage, fitlerSubset)

      //console.log(s)
      //console.log(e)
      //console.log('filtered subset')
      //console.log(fitlerSubset)

      setTotalAmountOfUsers(fitlerSubset.length)
      setCurrentUsers(mySlice(s, e, fitlerSubset))
      //console.log(mySlice(s, e, fitlerSubset))
      // console.log(mySlice(s,e,utilitiesData))
      setTotalPages(Math.ceil(totalUsers / 5))
      /* const res = await axios.get(baseurl);
      const pagedRes=await axios.get(`${baseurl}?_page=${currentPage}&_limit=5`);
      setBooks(pagedRes.data);
      setTotalAmountOfBooks(res.data.length);
      setTotalPages(Math.ceil(res.data.length/5)); */
    };

    fetchUtilities();
  }, [currentPage, allUsers, fitlerSubset]);


  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  let lastItem = messagesPerPage * currentPage <= totalAmountOfUsers ?
    messagesPerPage * currentPage : totalAmountOfUsers;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  /* console.log(String(currentMessages.length) + "total number of utilities")
  console.log(String(currentPage) + " current page")
  console.log(totalPages) */


  //end of pagination relatd stuff

  console.log(currentWidth)

  useEffect(() => {
    console.log("active message changed")
    console.log(currrentUsername)
    if (currrentUsername.length > 0) {
      console.log(currrentUsername)
      var clickedUser = getUserByUsername(allUsers, currrentUsername)
      console.log(clickedUser)
      setCurrentUsername(clickedUser.username)
      setCurrentCity(clickedUser.city)

      console.log(clickedUser)
    }

  }, [currrentUsername])


  if (isLoading) return <SpinnerLoading></SpinnerLoading>

  return (

    <div className='containter bg-light'>
      <div class="row equal-height-row 
                        d-flex flex-wrap">

        {(currrentUsername.length === 0) &&

          <div className="col-md-12 col-lg-6   bg-light 
rounded p-4 d-flex 
flex-column 
justify-content-between">
            <div>
              <h4>Alle Registerte Benutzer</h4>
              <br></br>

              {(currentWidth<1200) &&
              <p className="alert alert-primary"> {attentionMessage}</p>
              }

              

              <UserFilter Users={fitlerSubset} setUsers={setFilterSubset} allUsers={allUsers} setCurrentUsername={setCurrentUsername}  ></UserFilter>

              {currentUsers.map(u => (
                <UserSnippet key={u.username} user={u} currentUsername={currrentUsername} setCurrentUsername={setCurrentUsername}></UserSnippet>

              ))}

              {totalPages > 1 &&
                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}></Pagination>
              }








            </div>
          </div>

        }

        <div class="col-md-6 col-lg-4   bg-light rounded 
                            p-4 d-flex flex-column 
                            ">
          <div>

            {(currrentUsername.length === 0) &&

              <MessageToUser body={"Bite Wahlen ein Nutzer zur Vernetzen"} sender={''} setCurrentUser={setCurrentUsername}></MessageToUser>



            }


            {!(currrentUsername.length === 0) &&


              <MessageToUser sender={username} reciever={currrentUsername} setCurrentUser={setCurrentUsername} ></MessageToUser>



            }











          </div>
        </div>

      </div>



    </div>
  );
}