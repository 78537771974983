import React from 'react'

import { ShowOSMObject } from './ShowOSMObject';

import { sortOsmObjects } from '../../services/AuthService';

import { useState, useEffect } from 'react'
import axios from 'axios';

export const ShowEdits = () => {

    const [osmData, setOSMdata] = useState([]);

    const [completeObjects,setCompleteObjects]=useState([])

    const [incompleteObjects,setIncompleteObjects]=useState([])


    useEffect(() => {

        const fetchOSM = async () => {

            const res = await axios.get("http://localhost:9000/osmObjects/all")
            const allOSMObjects = res.data;
            /* const allKreisLabels=[]
            allKreise.map((m)=>{
             allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
            }); */








            console.log(allOSMObjects)

            const sortedObjects=sortOsmObjects(allOSMObjects)

            console.log(sortedObjects)

            setCompleteObjects(sortedObjects.complete)
            setIncompleteObjects(sortedObjects.incomplete)


            setOSMdata(allOSMObjects);



        };

        fetchOSM();
        console.log(osmData.length)
    }, [])



    return (

        <div className='container'>
            <div className='mt-3'>

              

                {completeObjects.map(o => (
                    // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
                    <ShowOSMObject key={o.id} status={o.status} objektart={o.objektart} osmid={o.id}></ShowOSMObject>
                ))}

                <br></br>

                {incompleteObjects.map(o => (
                    // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
                    <ShowOSMObject key={o.id} status={o.status} objektart={o.objektart} osmid={o.id}></ShowOSMObject>
                ))}

            </div>

        </div>

    )
}
