
import { useState } from "react";
import { activataAPICall } from "../../services/AuthService";


export const ActivateAccount = () => {

    const [username, setUsername] = useState('')
    const [activationCode, setActivtionCode] = useState('')

    const [activationSuccess, setActivationSuccess] = useState(false)
    const [activationFailure, setActivationFailure] = useState(false)


    async function handleActivationFormSubmission(e) {

        e.preventDefault();




        await activataAPICall(username, activationCode).then((response) => {
            console.log(response.data);
            setActivationSuccess(true)
        }).catch(error => {
            console.error(error);
            setActivationFailure(true)
        })

    }


    return (
        <div className='container'>
            <br /> <br />
            <div className='row'>
                <div className='col-md-12 col-lg-6 offset-lg-3'>
                    <div className='card'>
                    <img class="card-img-top" src={`${process.env.PUBLIC_URL}/networking-illustration.svg`}
                            height="100"
                            width="100"
                            alt='Missing image' />
                        <div className='card-header'>
                            <h2 className='text-center'> Aktivieren Ihre Konto </h2>
                        </div>

                        <div className='card-body'>
                            <form>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Username </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='username'
                                            className='form-control'
                                            placeholder='Enter username'
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Activation Code </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='email'
                                            className='form-control'
                                            placeholder='Enter Activation code'
                                            value={activationCode}
                                            onChange={(e) => setActivtionCode(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>


                                <div className="col-md-6 offset-md-6">
                                    <div className='form-group mb-3'>
                                        <button className='btn btn-primary' onClick={(e) => handleActivationFormSubmission(e)}>Submit</button>

                                    </div>


                                </div>


                            </form>

                            {activationSuccess &&
                                <div className="alert alert-success" role="alert">
                                    <p>You have successfully registered. Please check your email.</p>
                                </div>
                            }


                            {activationFailure &&
                                <div className="alert alert-danger" role="alert">
                                    <p>Either the username or your email or both are not available. Please use another combination</p>
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </div>


        </div>
    )


}