import { useState, useEffect } from "react"
import axios from 'axios';

import Select from 'react-select'

import { SearchBook } from "./SearchBook";
import { ShowUtility } from "./ShowUtility";
import { Pagination } from "./Pagination";
import { ShowMoreInfo } from "./ShowMoreInfo";

import { currentContext } from "../../SecurityContext";

import { Slider } from "./Slider";

import { urlStem } from "../../services/UrlService";

import { useContext } from "react";


export const SearchSPNVPage = () => {

  console.log(currentContext)

  const mystem=urlStem(currentContext)

  console.log(mystem)

  
  const baseurlHaltestelle = `${mystem}/bfrk_api/haltestelle?oevart=S&kreisid=`

  console.log(baseurlHaltestelle)

  const [kreisData, setKreisData] = useState([])





  const [kreisSelected, setKreisSelected] = useState('')

  const [idKreisSelected, setIdKreisSelected] = useState('')

  const [idHaltestelleSelected, setidHaltestelleSelected] = useState('')

  const [haltestelleSelected, setHaltestelleSelected] = useState('')

  const [haltestelleData, setHaltestelleData] = useState([])

  const [utilitiesData, setUtilitiesData] = useState([])

  const [utilityCategories, setUtilityCategories] = useState([])

  const [haltestelleName, setHaltestelleName] = useState('')

  //pagination related variables

  const [currentUtilities, setCurrentUtilities] = useState([])



  const [currentPage, setCurrentPage] = useState(1);
  const [utilitiesPerPage] = useState(5);
  const [totalAmountOfUtilities, setTotalAmountOfUtilities] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [showFilterButton, setShowFilterButton] = useState(true)

  const [showFilterBody, setShowFilterBody] = useState(true)

  const availableFilters = ["Rampe", "Aufzug", "Rolltreppe", "Parkplatz", "Fahrradanlage"]
  const [filteredUtilies, setFilteredUtilities] = useState([])

  const [temporaryFilteredData, setTemporaryFilteredData] = useState([])
  /* to have more control over when the filtered data will be rendered  */



  const [toggleAufzug, setToggleAufzug] = useState(false)
  const [toggleRampe, setToggleRampe] = useState(false)
  const [toggleRolltreppe, setToggleRolltreppe] = useState(false)
  const [toggleFahrradanlage,setToggleFahrradanlage]=useState(false)
  const [toggleParkplatz,setToggleParkplatz]=useState(false)
  const [toggleSelectAll, setToggleSelectAll] = useState(true)

  const [filterMode, setFilterMode] = useState(false)


  const handleInadvertantClick = () => {
    /* cleanup function that  resets state when  a user makes an unanticipated move */

    //  setKreisData([])

    setKreisSelected('')
    setIdKreisSelected('')
    setidHaltestelleSelected('')
    setHaltestelleSelected('')
    // setHaltestelleData([])
    setUtilitiesData([])
    setUtilityCategories([])
    setHaltestelleName('')
    setCurrentUtilities([])
    setCurrentPage(1)
    setTotalAmountOfUtilities(0)
    setTotalPages(0)
    setShowFilterButton(true)
    // setShowFilterBody(false)  to enable that the filter body is always visible. Its is deviation from desired behavior and possible source of bugs
    setToggleAufzug(false)
    setToggleRampe(false)
    setToggleRolltreppe(false)
    setToggleFahrradanlage(false)
    setToggleParkplatz(false)
    setToggleSelectAll(true)
    setFilterMode(false)
    setFilteredUtilities([])
    setTemporaryFilteredData([])







  }

  const handleIndvertantKreisChange = () => {
    /*soft reset */
    setidHaltestelleSelected('')
    setHaltestelleSelected('')
    // setHaltestelleData([])
    setUtilitiesData([])
    setUtilityCategories([])
    setHaltestelleName('')
    setCurrentUtilities([])
    setCurrentPage(1)
    setTotalAmountOfUtilities(0)
    setTotalPages(0)
    setShowFilterButton(true)
    // setShowFilterBody(false)  to enable that the filter body is always visible. Its is deviation from desired behavior and possible source of bugs
    setToggleAufzug(false)
    setToggleRampe(false)
    setToggleRolltreppe(false)
    setToggleFahrradanlage(false)
    setToggleParkplatz(false)
    setToggleSelectAll(true)
    setFilterMode(false)
    setFilteredUtilities([])
    setTemporaryFilteredData([])
    /* hard reset if required : handleReset()*/
  }


  const handleAufzugToggleChange = (toggleAufzug, toggleSelectAll) => {
    setToggleAufzug(!toggleAufzug)
    setToggleSelectAll(false)
  }

  const handleRampeToggleChange = (toggleRampe, toggleSelectAll) => {
    setToggleRampe(!toggleRampe)
    setToggleSelectAll(false)
  }

  const handleRolltreppeToggleChange = (toggleRolltreppe, toggleSelectAll) => {
    setToggleRolltreppe(!toggleRolltreppe)
    setToggleSelectAll(false)
  }

  const handleFahrradanlageToggleChange=(toggleFahrradanlage,toggleSelectAll)=>{
    setToggleFahrradanlage(!toggleFahrradanlage)
    setToggleSelectAll(false)
  }

  const handleParkplatzToggleChange=(toggleParkplatz,toggleSelectAll)=>{
    setToggleParkplatz(!toggleParkplatz)
    setToggleSelectAll(false)
  }

  const handleFilterSubmission = () => {
    // handleFilterSubmission()

    setFilterMode(true)
    setFilteredUtilities(temporaryFilteredData)

  }

  const handleReset = () => {
    window.location.reload();
  }


  const handleClearFilters = () => {
    setFilterMode(false)
    setFilteredUtilities([])
    setTemporaryFilteredData([])
    // setShowFilterBody(false) keep showing the filters
    setShowFilterButton(true)
    setToggleAufzug(false)
    setToggleRampe(false)
    setToggleRolltreppe(false)
    setToggleFahrradanlage(false)
    setToggleParkplatz(false)
    setToggleSelectAll(true)
  }
  const handleFilterApplication = () => {
    console.log("handle filter application called")
    if (toggleSelectAll) {
      /* if(toggleSelectAll ||(!toggleAufzug &&!toggleRolltreppe&&!toggleRolltreppe)) */
      console.log("please reset")
      setFilteredUtilities(utilitiesData)

    } else {
      let currentSelection = []
      if (toggleAufzug) {
        const aufzugSlice = sliceByCategory('Aufzug')
        console.log("Aufzug selected")
        console.log(utilityCategories)
        console.log(aufzugSlice)
        aufzugSlice.map((m) => currentSelection.push(m))
      }

      if (toggleRampe) {
        const rampeSlice = sliceByCategory('Rampe')
        rampeSlice.map((m) => { currentSelection.push(m) })
      }

      if (toggleRolltreppe) {
        const rolltreppeSlice = sliceByCategory('Rolltreppe')
        rolltreppeSlice.map((m) => { currentSelection.push(m) })
      }

      if (toggleFahrradanlage) {
        const fahrradnanlageSlice = sliceByCategory('Fahrradanlage')
        fahrradnanlageSlice.map((m) => { currentSelection.push(m) })
      }

      if (toggleParkplatz) {
        const parkplatzSlice = sliceByCategory('Parkplatz')
        parkplatzSlice.map((m) => { currentSelection.push(m) })
      }


      

      console.log(currentSelection)
      // setUtilitiesData(currentSelection)
      setTemporaryFilteredData(currentSelection)
      // setFilteredUtilities(currentSelection)
    }


  }


  useEffect(() => {
    console.log("toggle changed")
    handleFilterApplication()


  }, [toggleAufzug, toggleRampe, toggleRolltreppe,toggleFahrradanlage,toggleParkplatz, toggleSelectAll])

  useEffect(() => {
    if (filterMode) {
      handleFilterApplication()
    }
  }, [filterMode])





  function sliceByCategory(category) {
    const categorySlice = []
    utilitiesData.map((m) => {
      if (m.objektart === category) {
        categorySlice.push(m)
      }
    })
    return categorySlice
  }


  function getAllCategories(presentCategories) {
    const categories = []
    presentCategories.map((e) => { categories.push(e.objektart) })
    const uniqueCategories = Array.from(new Set(categories))
    return uniqueCategories

  }





  useEffect(() => {

    const fetchKries = async () => {
       

      const res = await axios.get(`${mystem}/bfrk_api/kreise?oevart=S`)
      const allKreise = res.data;
      /* const allKreisLabels=[]
      allKreise.map((m)=>{
       allKreisLabels.push({"name":m.name,"id":m.kreisschluessel})
      }); */









      const allKreiseCategories = []
      allKreise.map((m) => {
        allKreiseCategories.push({ "label": m.name, "value": m.kreisschluessel })
      })




      setKreisData(allKreiseCategories)
    };

    fetchKries();
    //  console.log(utilitiesData.length)
  }, [])


  useEffect(() => {
    console.log("updated kreise")
  
   
  }, [kreisData])
  


  useEffect(() => {

    const fetchHaltestelles = async () => {

      // console.log(kreisSelected)
      const hatestelleurl = `${baseurlHaltestelle}${idKreisSelected}`;
      // console.log(hatestelleurl)
      const res = await axios.get(hatestelleurl);
      const allHaltestelleData = res.data
      const haltestelleOptions = []
      allHaltestelleData.map((h) => {
        h.dhid.length > 0 &&
          haltestelleOptions.push({ "label": `${h.name}, ${h.ortsteil}, Gemeinde: ${h.gemeinde}`, "value": h.dhid })
      })
      setHaltestelleData(haltestelleOptions)







    };

    if (idKreisSelected) {
      console.log("active state")
      fetchHaltestelles()
    }


  },

    [idKreisSelected]);

  useEffect(() => {
    // console.log("re-render after kreis selected name change")

  }, [kreisSelected])




  useEffect(() => {
    const fetchUtilities = async () => {
      console.log(idHaltestelleSelected)

      const utilityurl = `${mystem}/bfrk_api/haltestellenobjektart/${idHaltestelleSelected}?oevart=S`;
      // console.log(utilityurl)
      const res = await axios.get(utilityurl);
      const allHaltestelleData = res.data
      const haltestelleUtilities = []
      allHaltestelleData.map((u) => {

        haltestelleUtilities.push({ "id": u.id, "objektart": u.objektart, "img": `/${u.objektart.toLowerCase()}.svg` })
      })

      const categories = getAllCategories(allHaltestelleData)
      setUtilitiesData(haltestelleUtilities)
      setUtilityCategories(categories)



    };

    if (idHaltestelleSelected) {
      fetchUtilities();

    }


  }
    , [idHaltestelleSelected])


  function startIndex(cp, perPage) {
    var s
    if (cp === 1) {
      s = 0
    } else {
      s = (cp - 1) * perPage
    }
    return s
  }
  function endIndex(cp, startIndex, totalPages, perPage, allData) {
    var e

    if (cp === totalPages) {
      e = allData.length - 1
    } else {
      e = startIndex + perPage
    }
    return e
  }



  function mySlice(s, e, A) {
    if (s === e) {
      return [A[e]]
    } else {
      return A.slice(s, e)
    }
  }
  useEffect(() => {
    const fetchUtilities = () => {


      var totalUtilities = utilitiesData.length
      var totalPages = Math.ceil(totalUtilities / 5)
      var s = startIndex(currentPage, utilitiesPerPage)
      var e = endIndex(currentPage, s, totalPages, utilitiesPerPage, utilitiesData)

      console.log(s)
      console.log(e)

      setTotalAmountOfUtilities(utilitiesData.length)
      setCurrentUtilities(mySlice(s, e, utilitiesData))
      // console.log(mySlice(s,e,utilitiesData))
      setTotalPages(Math.ceil(totalUtilities / 5))
      /* const res = await axios.get(baseurl);
      const pagedRes=await axios.get(`${baseurl}?_page=${currentPage}&_limit=5`);
      setBooks(pagedRes.data);
      setTotalAmountOfBooks(res.data.length);
      setTotalPages(Math.ceil(res.data.length/5)); */
    };

    fetchUtilities();
  }, [utilitiesData, currentPage]);


  const indexOfLastUtility = currentPage * utilitiesPerPage;
  const indexOfFirstUtility = indexOfLastUtility - utilitiesPerPage;
  let lastItem = utilitiesPerPage * currentPage <= totalAmountOfUtilities ?
    utilitiesPerPage * currentPage : totalAmountOfUtilities;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  console.log(String(currentUtilities.length) + "total number of utilities")
  console.log(String(currentPage) + " current page")
  console.log(totalPages)


  const handleKreisChange = (e) => {

    /* const currentSelectedKreis = e.target.value;
    const currentSelectedName=e.target.options[e.target.selectedIndex].text */

    setHaltestelleData([])
    setKreisSelected(e.value);
    setIdKreisSelected(e.value);


    /* setIdKreisSelected(currentSelectedKreis)
    setKreisSelected(currentSelectedName); */

    setidHaltestelleSelected('')
    setHaltestelleSelected('')
    setHaltestelleName('')
    setUtilitiesData([])

    if (utilitiesData.length > 0) {
      console.log("inadvertant kreis change")
      handleIndvertantKreisChange()

    }
    /*  if(showFilter){
       console.log(showFilter)
       setShowFilter(!showFilter)
     }
     if(filterMode){
       setFilterMode(!filterMode)
     } */
    // setFilterMode(false)
    // setShowFilter(false)
    /* console.log(currentSelectedKreis);
    console.log(currentSelectedName); */




  }

  const handleHaltestelleChange = (e) => {
    /* const currentSelectedHaltestelle=e.value;
    const currentSelectedName=e.options[e.target.selectedIndex].text;

    console.log(currentSelectedHaltestelle)
    console.log(currentSelectedName) */
    setUtilitiesData([])
    // setShowFilter(false)
    // setFilterMode(false)
    setCurrentPage(1)

    setidHaltestelleSelected(e.value)
    setHaltestelleSelected(e.value)
    setHaltestelleName(e.label)

    if (!showFilterBody) {
      handleInadvertantClick()
      /* setShowFilterBody(false)
      setShowFilterButton(true) */

    }
    /* if(showFilter){
      setShowFilter(!showFilter)
    }
    if(filterMode){
      setFilterMode(!filterMode)
    } */

  }

  const handleFilterRequest = () => {
    console.log("filer to change")
    setShowFilterBody(!showFilterBody)
    setShowFilterButton(!showFilterButton)

  }

  useEffect(() => {
    console.log("filter requested")

  }, [showFilterButton])


  return (
    <div>
      <div className='container'>
        <div>
          <div className='row mt-5'>

            <div className='col-6'>
              <Select
                placeholder='Select a Kreis'
                isSearchable
                // value={kreisSelected}
                options={kreisData}
                onChange={handleKreisChange}
              />
            </div>
          </div>

          <br></br>
          <div className='row mt-5'>

            <div className="col-6">

              <Select
                placeholder='Select a Haltestelle'
                isSearchable

                value={idHaltestelleSelected ? { "label": haltestelleName, "value": idHaltestelleSelected } : null}
                options={haltestelleData}
                onChange={handleHaltestelleChange}
              />

            </div>



          </div>


          <div className='row mt-5'>

            <div className="col-4">

              {/*  {(utilitiesData.length>0 && (utilityCategories.includes("Rampe") ||utilityCategories.includes("Aufzug")||utilityCategories.includes("Rolltreppe"))) &&
                                
                                <button className="btn btn-primary" onClick={handleFilterRequest}>
                                   
                                                {showFilterButton ? "Filteroptionen anzeigen" : "Filteroptionen ausblenden"}
                                </button>
                                } */}

            </div>

            <br></br>
            <br></br>

            {(showFilterBody && utilitiesData.length > 0 && utilityCategories.includes("Rampe") || utilityCategories.includes("Aufzug") || utilityCategories.includes("Rolltreppe") || utilityCategories.includes("Parkplatz") || utilityCategories.includes("Fahrradanlage")) &&

              <div className="card text-dark bg-light  mb-3 w-75" >
                <div className="card-body">
                  <h5 className="card-title">Filter anwenden</h5>
                  <h6 className="card-subtitle mb-2 text-muted"></h6>
                  <div className="row">


                    {utilityCategories.includes("Aufzug") &&
                      <div className="col-4"> Aufzug <Slider toggle={toggleAufzug} handleToggle={handleAufzugToggleChange}></Slider></div>


                    }

                    {utilityCategories.includes("Rampe") &&

                      <div className="col-4"> Rampe <Slider toggle={toggleRampe} handleToggle={handleRampeToggleChange}></Slider></div>



                    }

                    {
                      utilityCategories.includes("Rolltreppe") &&

                      <div className="col-4"> Rolltreppe <Slider toggle={toggleRolltreppe} handleToggle={handleRolltreppeToggleChange}></Slider></div>


                    }

                  </div>
                  <div className="row">
                   

                    {utilityCategories.includes("Fahrradanlage") &&

                      <div className="col-4"> Fahrradanlage <Slider toggle={toggleFahrradanlage} handleToggle={handleFahrradanlageToggleChange}></Slider></div>



                    }

                    {
                      utilityCategories.includes("Parkplatz") &&

                      <div className="col-8"> Parkplatz <Slider toggle={toggleParkplatz} handleToggle={handleParkplatzToggleChange}></Slider></div>


                    }
                  </div>

                  <br></br>
                  <br></br>

                  
                  <div className="mt-3">
                    <div className="row">
                      <div className="col-4">
                        <button className="btn btn-primary" onClick={handleFilterSubmission}>Filter anwenden</button>
                      </div>
                      <div className="col-4">
                        <button className="btn btn-primary" onClick={handleClearFilters}>Filters Löschen</button>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col">Crime<Slider toggle={selectAction} handleToggle={handleActionToggleChange}></Slider></div>
                                      <div class="col"> Action<Slider toggle={selectAction} handleToggle={handleActionToggleChange}></Slider></div> */}



                </div>
              </div>

            }



          </div>


          <div className='mt-3'>
            <br></br>
            <br></br>

            {(utilitiesData.length > 0 && !filterMode) &&
              <h2>
                Es gibt {utilitiesData.length} Einrichtungen  an dieser Haltestelle

              </h2>
            }
          </div>

          {!filterMode && currentUtilities.map(utility => (
            // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
            <ShowMoreInfo key={utility.id} utility={utility} haltestelle={idHaltestelleSelected}></ShowMoreInfo>

          ))}

          {(!filterMode && totalPages > 1) &&
            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}></Pagination>}

          {(filteredUtilies.length === 0 && filterMode) &&
            <h2>
              Es gibt keine Einrichtungen des gewählten Typs an dieser Haltestelle
            </h2>

          }


          {(filteredUtilies.length > 0 && filterMode) &&
            <h2>
              Es gibt {filteredUtilies.length} Einrichtungen des gewählten Typs an dieser Haltestelle

            </h2>
          }

          {filterMode && filteredUtilies.map(utility => (
            // <ShowUtility key={utility.key} utility={utility} haltestelle={idHaltestelleSelected}></ShowUtility>
            <ShowMoreInfo key={utility.id} utility={utility} haltestelle={idHaltestelleSelected}></ShowMoreInfo>

          ))





          }

        </div>
      </div>
    </div>
  );
}